import React, { useEffect, useState, useRef } from "react";
import { Loader, MemberAvatar, AlertModal } from "@/components";
import { Link, useNavigate, useParams } from "react-router-dom";
import moment from "moment-timezone";
import InPersonIcon from "@/assets/sofa.svg";
import VirtualIcon from "@/assets/video-recorder.svg";
import PhoneSpeakerIcon from "@/assets/phone-speaker.svg";
import GroupsPlaceholderIcon from "@/assets/group-avatar-placeholder.svg";
import PhoneIcon from "@/assets/phone.svg";
import ShareIcon from "@/assets/share.svg";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { ReactSVG } from "react-svg";
import { SendText } from "@/components";
import WaitingStatus from "@/Routes/Appointments/components/Sessions/components/WaitingStatus";
import "./WaitingBar.scss";

const APP_ENV = import.meta.env.VITE_APP_ENV || "PROD";
const isMobile = window.innerWidth <= 480;

function WaitingBar(props) {
  const { sessionCategory, sessionType, sessionId, patientId } = useParams();
  let loading = props.sessions?.loading;
  let upcomingSessions = props.sessions.data.filter(session => {
    const today = new Date(
      new Date().toLocaleString("en", {
        timeZone: props.preferredTimezone?.name || "America/Los_Angeles",
      })
    );
    today.setHours(0, 0, 0, 0);
    const dateToCheck = new Date(
      new Date(session.startTime).toLocaleString("en", {
        timeZone: props.preferredTimezone?.name || "America/Los_Angeles",
      })
    );
    dateToCheck.setHours(0, 0, 0, 0);
    return (
      dateToCheck.getTime() === today.getTime()
      // &&
      // !!session.endTime &&
      // new Date(session.endTime) >= new Date()
    );
  });
  upcomingSessions = upcomingSessions.sort(
    (session1, session2) => new Date(session1.startTime) - new Date(session2.startTime)
  );
  useEffect(() => {
    if (!props.sessions.loading && props.sessions.data.length === 0) {
      props.getSessionsByPractitionerRole();
    }
  }, []);
  let patientsWaiting = 0;
  if (upcomingSessions?.length > 0) {
    upcomingSessions.forEach(session => {
      if (session.sessionCategory === "INDIVIDUAL") {
        let patientId = session.patientId;
        if (props.patientStatus?.[patientId]?.status === "waitlisted") {
          patientsWaiting = patientsWaiting + 1;
        }
      } else {
        let groupId = session.groupId;
        let totalWaitlistedPatients = 0;
        if (props.groupStatus?.[groupId]?.patients) {
          Object.keys(props.groupStatus?.[groupId]?.patients)?.forEach(patientId => {
            if (props.groupStatus?.[groupId]?.patients[patientId].status === "waitlisted") {
              totalWaitlistedPatients++;
            }
          });
        }
        if (totalWaitlistedPatients > 0) {
          patientsWaiting = patientsWaiting + 1;
        }
      }
    });
  }
  if (!patientsWaiting) {
    return <></>;
  }
  return (
    <div className="waiting-bar-container">
      <div className="waiting-bar-label">Patients Waiting: </div>
      <div className="sessions-list">
        {upcomingSessions.map(session => {
          return (
            <WaitingStatus
              session={session}
              overlayPlacement="bottom"
              showName={true}
              openDetails={true}
              key={session.id}
            />
          );
        })}
      </div>
    </div>
  );
}

export default WaitingBar;
