import React, { useEffect, useState, useRef } from "react";
import { SecondaryButton, Loader, PrimaryButton } from "@/components";
import { Modal, OverlayTrigger, ProgressBar, Tooltip, Form, Overlay } from "react-bootstrap";
import moment from "moment-timezone";
import DeleteIcon from "@/assets/delete-icon.svg";
import "./TreatmentPlan.scss";
import Calendar from "react-calendar";

const inputMapping = {
  description: {
    label: "Description",
    type: "text",
    placeholder: "",
  },
  behavioral_description: {
    label: "Behavioral Description",
    type: "textarea",
    placeholder: "Behavioral Description",
  },
  patient_description: {
    label: "Patient Description",
    type: "textarea",
    placeholder: "Patient Description",
  },
  strength: {
    label: "Strength",
    type: "textarea",
    placeholder: "Strength",
  },
  comment: {
    label: "Comment",
    type: "textarea",
    placeholder: "Comment",
  },
  responsible_party: {
    label: "Responsible Party",
    type: "single_choice",
    options: ["Provider", "Patient"],
  },
  target_date: {
    label: "Target Date",
    type: "date",
    placeholder: "MM/DD/YYYY",
  },
  target_number_of_sessions: {
    label: "Target No. of Sessions",
    type: "number",
    placeholder: "No. of sessions",
  },
  frequency: {
    label: "Frequency",
    type: "number",
    placeholder: "Frequency",
  },
  lengths: {
    label: "Lengths",
    type: "number",
    placeholder: "Lengths",
  },
  duration: {
    label: "Duration",
    type: "number",
    placeholder: "Duration",
  },
};

const getNewProblem = index => {
  return {
    problem_id: `problem-${index}`,
    description: "",
    behavioral_description: "",
    patient_description: "",
    strength: "",
    comment: "",
    goals: [getNewGoal(index, 0)],
  };
};

const getNewGoal = (index, goalIndex) => {
  return {
    goal_id: `goal-${index}-${goalIndex}`,
    description: "",
    responsible_party: "",
    target_date: "",
    target_number_of_sessions: "",
    patient_description: "",
    comment: "",
    objectives: [getNewObjective(index, goalIndex, 0)],
  };
};

const getNewObjective = (index, goalIndex, objectiveIndex) => {
  return {
    objective_id: `objective-${index}-${goalIndex}-${objectiveIndex}`,
    description: "",
    target_date: "",
    target_number_of_sessions: "",
    patient_description: "",
    comment: "",
    interventions: [getNewIntervention(index, goalIndex, objectiveIndex, 0)],
  };
};

const getNewIntervention = (index, goalIndex, objectiveIndex, interventionIndex) => {
  return {
    intervention_id: `intervention-${index}-${goalIndex}-${objectiveIndex}-${interventionIndex}`,
    description: "",
    frequency: 1,
    lengths: 1,
    duration: 1,
    comment: "",
  };
};

function TreatmentPlan(props) {
  const [generateTreatmentPlanModalOpen, setGenerateTreatmentPlanModalOpen] = useState(false);
  const patientId = props.patientId;
  useEffect(() => {
    props.getTreatmentPlan({ patientId });
  }, []);
  const lastUpdatedOn = props.treatmentPlan?.[patientId]?.data?.updatedAt
    ? moment(props.treatmentPlan[patientId].data.updatedAt)
        .tz(props.preferredTimezone?.name || "America/Los_Angeles")
        .format("MMM DD, YYYY")
    : moment()
        .tz(props.preferredTimezone?.name || "America/Los_Angeles")
        .format("MMM DD, YYYY");
  const treatmentPlanData = props.treatmentPlan?.[patientId]?.data?.plan;
  return (
    <>
      <Loader loading={props.treatmentPlan?.[patientId]?.loading}>
        <div className="treatment-plan-container">
          {props.treatmentPlan?.[patientId]?.data ? (
            <div className="treatment-plan-container">
              <div className="treatment-plan-header">
                <div className="treatment-plan-last-updated">Last updated on: {lastUpdatedOn}</div>
                <div className="action-btns">
                  <SecondaryButton onClick={() => setGenerateTreatmentPlanModalOpen(true)}>
                    Revise Treatment Plan
                  </SecondaryButton>
                </div>
              </div>
              <div className="treatment-plan-content-container">
                {treatmentPlanData?.["problems"]?.length > 0 && (
                  <div className="treatment-plan-problems-container">
                    <ol className="treatment-plan-problems-list">
                      {treatmentPlanData?.["problems"].map((problem, problemIndex) => {
                        return (
                          <li
                            className="treatment-plan-problem-item"
                            key={problem.problem_id}
                          >
                            <div className="treatment-plan-input-container with-textarea">
                              <div className="treatment-plan-input-label">Problem:</div>
                              <div className="treatment-plan-input">
                                {problem.description || "-"}
                              </div>
                            </div>
                            {/* <div className="treatment-plan-input-container with-textarea">
                              <div className="treatment-plan-input-label">
                                {inputMapping["behavioral_description"].label}:
                              </div>
                              <div className="treatment-plan-input">
                                {problem.behavioral_description || "-"}
                              </div>
                            </div> */}
                            {/* <div className="treatment-plan-input-container with-textarea">
                              <div className="treatment-plan-input-label">
                                {inputMapping["patient_description"].label}:
                              </div>
                              <div className="treatment-plan-input">
                                {problem.patient_description || "-"}
                              </div>
                            </div> */}
                            {/* <div className="treatment-plan-input-container with-textarea">
                              <div className="treatment-plan-input-label">
                                {inputMapping["strength"].label}:
                              </div>
                              <div className="treatment-plan-input">{problem.strength || "-"}</div>
                            </div> */}
                            {/* <div className="treatment-plan-input-container with-textarea">
                              <div className="treatment-plan-input-label">
                                {inputMapping["comment"].label}:
                              </div>
                              <div className="treatment-plan-input">{problem.comment || "-"}</div>
                            </div> */}
                            {problem?.goals?.length > 0 && (
                              <div className="treatment-plan-goals-container">
                                <div className="treatment-plan-goals-label">Goals:</div>
                                <ol className="treatment-plan-goals-list">
                                  {problem.goals.map((goal, goalIndex) => {
                                    return (
                                      <li
                                        className="treatment-plan-goal-item"
                                        key={goal.goal_id}
                                      >
                                        <div className="treatment-plan-input-container with-textarea">
                                          <div className="treatment-plan-input-label">Goal:</div>
                                          <div className="treatment-plan-input">
                                            {goal.description || "-"}
                                          </div>
                                        </div>
                                        <div className="treatment-plan-input-container">
                                          <div className="treatment-plan-input-label">
                                            {inputMapping["responsible_party"].label}:
                                          </div>
                                          <div className="treatment-plan-input">
                                            {goal.responsible_party || "-"}
                                          </div>
                                        </div>
                                        <div className="treatment-plan-goal-input-list">
                                          <div className="treatment-plan-input-container">
                                            <div className="treatment-plan-input-label">
                                              {inputMapping["target_date"].label}:
                                            </div>
                                            <div className="treatment-plan-input">
                                              {goal.target_date
                                                ? moment(goal.target_date).format("MM/DD/YYYY")
                                                : "-"}
                                            </div>
                                          </div>
                                          <div className="treatment-plan-input-container">
                                            <div className="treatment-plan-input-label">
                                              {inputMapping["target_number_of_sessions"].label}:
                                            </div>
                                            <div className="treatment-plan-input">
                                              {goal.target_number_of_sessions || "-"}
                                            </div>
                                          </div>
                                        </div>
                                        {/* <div className="treatment-plan-input-container with-textarea">
                                          <div className="treatment-plan-input-label">
                                            {inputMapping["patient_description"].label}:
                                          </div>
                                          <div className="treatment-plan-input">
                                            {goal.patient_description || "-"}
                                          </div>
                                        </div> */}
                                        {/* <div className="treatment-plan-input-container with-textarea">
                                          <div className="treatment-plan-input-label">
                                            {inputMapping["comment"].label}:
                                          </div>
                                          <div className="treatment-plan-input">
                                            {goal.comment || "-"}
                                          </div>
                                        </div> */}
                                        {goal?.objectives?.length > 0 && (
                                          <div className="treatment-plan-objectives-container">
                                            <div className="treatment-plan-objectives-label">
                                              Objectives:
                                            </div>
                                            <ol className="treatment-plan-objectives-list">
                                              {goal.objectives.map((objective, objectiveIndex) => {
                                                return (
                                                  <li
                                                    className="treatment-plan-objective-item"
                                                    key={objective.objective_id}
                                                  >
                                                    <div className="treatment-plan-input-container with-textarea">
                                                      <div className="treatment-plan-input-label">
                                                        Objective:
                                                      </div>
                                                      <div className="treatment-plan-input">
                                                        {objective.description || "-"}
                                                      </div>
                                                    </div>
                                                    {/* <div className="treatment-plan-input-container">
                                                      <div className="treatment-plan-input-label">
                                                        {inputMapping["target_date"].label}:
                                                      </div>
                                                      <div className="treatment-plan-input">
                                                        {objective.target_date
                                                          ? moment(objective.target_date).format(
                                                              "MM/DD/YYYY"
                                                            )
                                                          : "-"}
                                                      </div>
                                                    </div> */}
                                                    {/* <div className="treatment-plan-input-container">
                                                      <div className="treatment-plan-input-label">
                                                        {
                                                          inputMapping["target_number_of_sessions"]
                                                            .label
                                                        }
                                                        :
                                                      </div>
                                                      <div className="treatment-plan-input">
                                                        {objective.target_number_of_sessions || "-"}
                                                      </div>
                                                    </div> */}
                                                    {/* <div className="treatment-plan-input-container with-textarea">
                                                      <div className="treatment-plan-input-label">
                                                        {inputMapping["patient_description"].label}:
                                                      </div>
                                                      <div className="treatment-plan-input">
                                                        {objective.patient_description || "-"}
                                                      </div>
                                                    </div> */}
                                                    {/* <div className="treatment-plan-input-container with-textarea">
                                                      <div className="treatment-plan-input-label">
                                                        {inputMapping["comment"].label}:
                                                      </div>
                                                      <div className="treatment-plan-input">
                                                        {objective.comment || "-"}
                                                      </div>
                                                    </div> */}
                                                    {objective?.interventions?.length > 0 && (
                                                      <div className="treatment-plan-interventions-container">
                                                        <div className="treatment-plan-interventions-label">
                                                          Interventions:
                                                        </div>
                                                        <ol className="treatment-plan-interventions-list">
                                                          {objective.interventions.map(
                                                            (intervention, interventionIndex) => {
                                                              return (
                                                                <li
                                                                  className="treatment-plan-intervention-item"
                                                                  key={intervention.intervention_id}
                                                                >
                                                                  <div className="treatment-plan-input-container with-textarea">
                                                                    <div className="treatment-plan-input-label">
                                                                      Intervention:
                                                                    </div>
                                                                    <div className="treatment-plan-input">
                                                                      {intervention.description ||
                                                                        "-"}
                                                                    </div>
                                                                  </div>
                                                                  <div className="treatment-plan-intervention-input-list">
                                                                    <div className="treatment-plan-input-container">
                                                                      <div className="treatment-plan-input-label">
                                                                        {
                                                                          inputMapping["frequency"]
                                                                            .label
                                                                        }
                                                                        :
                                                                      </div>
                                                                      <div className="treatment-plan-input">
                                                                        {intervention.frequency ||
                                                                          "-"}
                                                                      </div>
                                                                    </div>
                                                                    <div className="treatment-plan-input-container">
                                                                      <div className="treatment-plan-input-label">
                                                                        {
                                                                          inputMapping["lengths"]
                                                                            .label
                                                                        }
                                                                        :
                                                                      </div>
                                                                      <div className="treatment-plan-input">
                                                                        {intervention.lengths ||
                                                                          "-"}
                                                                      </div>
                                                                    </div>
                                                                    <div className="treatment-plan-input-container">
                                                                      <div className="treatment-plan-input-label">
                                                                        {
                                                                          inputMapping["duration"]
                                                                            .label
                                                                        }
                                                                        :
                                                                      </div>
                                                                      <div className="treatment-plan-input">
                                                                        {intervention.duration ||
                                                                          "-"}
                                                                      </div>
                                                                    </div>
                                                                  </div>
                                                                  {/* <div className="treatment-plan-input-container with-textarea">
                                                                    <div className="treatment-plan-input-label">
                                                                      {
                                                                        inputMapping["comment"]
                                                                          .label
                                                                      }
                                                                      :
                                                                    </div>
                                                                    <div className="treatment-plan-input">
                                                                      {intervention.comment || "-"}
                                                                    </div>
                                                                  </div> */}
                                                                </li>
                                                              );
                                                            }
                                                          )}
                                                        </ol>
                                                      </div>
                                                    )}
                                                  </li>
                                                );
                                              })}
                                            </ol>
                                          </div>
                                        )}
                                      </li>
                                    );
                                  })}
                                </ol>
                              </div>
                            )}
                          </li>
                        );
                      })}
                    </ol>
                  </div>
                )}
              </div>
            </div>
          ) : (
            <div className="generate-treatment-plan-container">
              <PrimaryButton onClick={() => setGenerateTreatmentPlanModalOpen(true)}>
                Generate Treatment Plan
              </PrimaryButton>
            </div>
          )}
        </div>
      </Loader>
      <TreatmentPlanModal
        modalOpen={generateTreatmentPlanModalOpen}
        toggleModal={setGenerateTreatmentPlanModalOpen}
        treatmentPlan={props.treatmentPlan?.[patientId]}
        generateTreatmentPlan={props.generateTreatmentPlan}
        patientId={patientId}
        selectedUserRole={props.selectedUserRole}
      />
    </>
  );
}

export default TreatmentPlan;

const TreatmentPlanModal = props => {
  const [treatmentPlanData, setTreatmentPlanData] = useState(null);
  const [showGoalCalendar, setShowGoalCalendar] = useState({});
  const [showObjectiveCalendar, setShowObjectiveCalendar] = useState({});
  const goalCalendarRef = useRef({});
  const objectiveCalendarRef = useRef({});
  useEffect(() => {
    if (props.modalOpen) {
      if (props.treatmentPlan?.data?.plan) {
        setTreatmentPlanData(props.treatmentPlan.data.plan);
      } else {
        setTreatmentPlanData({
          problems: [getNewProblem(0)],
        });
        // setTreatmentPlanData({
        //   problems: [
        //     {
        //       problem_id: "problem-0",
        //       description:
        //         "John is experiencing severe depression characterized by persistent low mood, feelings of worthlessness, and difficulty concentrating. He also suffers from frequent panic attacks related to anxiety.",
        //       behavioral_description:
        //         "Tony sought therapy due to a combination of professional trauma and personal life challenges. They experienced a significant emotional event when a young, healthy patient named Amelia died unexpectedly during surgery, which Tony describes as a failure on their part, leading to a loss of faith in their abilities and a subsequent career change. This event appears to have reactivated the trauma from their father's death, which occurred when Tony was 19. Additionally, Tony is facing marital issues, a lack of intimacy with their spouse, and feelings of disconnection from their family, contributing to what they describe as depression. They also mention the onset of heart arrhythmias, which led their cardiologist to recommend therapy. Tony's brother, Frankie, is also going through a tough time, and although Tony has offered to help, their relationship is strained. Tony's goal for therapy is to find a balance between work and personal life and to address the underlying trauma that may be impacting their current functioning.",
        //       patient_description:
        //         "The client, referred to as Tony, is a former cardiothoracic surgeon who previously worked at the Cleveland Clinic and is currently affiliated with the University of Washington. Tony's age is not explicitly stated, but it is known that they were 19 at the time of their father's death and went to school until they were 34. They are married, with children and a stepson, and have recently experienced heart arrhythmias. Tony's demographics, such as exact age, ethnicity, and contact details, are not provided in the conversation.",
        //       strength: "",
        //       comment: "",
        //       goals: [
        //         {
        //           goal_id: "goal-0-0",
        //           description:
        //             "Improve overall well-being by enhancing work-life balance and strengthening personal relationships",
        //           responsible_party: "Provider",
        //           target_date: "2024-10-02T18:30:00.000Z",
        //           target_number_of_sessions: "4",
        //           patient_description: "",
        //           comment: "",
        //           objectives: [
        //             {
        //               objective_id: "objective-0-0-0",
        //               description:
        //                 "Create a pie chart of current work-life balance and compare it to a desired pie chart,\nfocusing on work, family, relationships, hobbies, friends, and community activities",
        //               target_date: "2024-10-03T18:30:00.000Z",
        //               target_number_of_sessions: "3",
        //               patient_description: "",
        //               comment: "",
        //               interventions: [
        //                 {
        //                   intervention_id: "intervention-0-0-0-0",
        //                   description: "Cognitive Behavioral Therapy (CBT)",
        //                   frequency: "2",
        //                   lengths: "4",
        //                   duration: 1,
        //                   comment: "",
        //                 },
        //               ],
        //             },
        //             {
        //               objective_id: "objective-0-0-1",
        //               description:
        //                 "Dedicate at least one hour per day to spend quality time with their spouse and children,\nengaging in activities that promote connection and bonding",
        //               target_date: "2024-10-01T18:30:00.000Z",
        //               target_number_of_sessions: "2",
        //               patient_description: "",
        //               comment: "",
        //               interventions: [
        //                 {
        //                   intervention_id: "intervention-0-0-1-0",
        //                   description:
        //                     "Exploring Emotions: The therapist encourages the client to discuss and reflect on their emotional experiences, particularly around significant events such as the death of a patient and their father, to facilitate emotional processing",
        //                   frequency: 1,
        //                   lengths: 1,
        //                   duration: 1,
        //                   comment: "",
        //                 },
        //               ],
        //             },
        //           ],
        //         },
        //         {
        //           goal_id: "goal-0-1",
        //           description:
        //             "Heal from past traumas to reduce their emotional burden and improve mental health",
        //           responsible_party: "Patient",
        //           target_date: "",
        //           target_number_of_sessions: "4",
        //           patient_description: "",
        //           comment: "",
        //           objectives: [
        //             {
        //               objective_id: "objective-0-1-0",
        //               description:
        //                 'Read recommended books on trauma, such as "The Body Keeps the Score," to gain a deeper understanding of the effects of trauma and potential healing strategies',
        //               target_date: "",
        //               target_number_of_sessions: "",
        //               patient_description: "",
        //               comment: "",
        //               interventions: [
        //                 {
        //                   intervention_id: "intervention-0-1-0-0",
        //                   description:
        //                     "Trauma-Informed Care: The therapist recognizes signs of trauma in the client's narrative and suggests working through this trauma as a key component of therapy",
        //                   frequency: 1,
        //                   lengths: 1,
        //                   duration: 1,
        //                   comment: "",
        //                 },
        //               ],
        //             },
        //           ],
        //         },
        //       ],
        //     },
        //   ],
        // });
      }
    }
  }, [props.treatmentPlan?.data?.plan, props.modalOpen]);
  const onInputChange = (
    key,
    value,
    type,
    problemIndex,
    goalIndex,
    objectiveIndex,
    interventionIndex
  ) => {
    setTreatmentPlanData(prevData => {
      let data = prevData ? { ...prevData } : {};
      if (type === "problem") {
        data = {
          ...data,
          problems:
            data.problems?.length > 0
              ? data.problems.map((problem, tempProblemIndex) => {
                  if (tempProblemIndex === problemIndex) {
                    return {
                      ...problem,
                      [key]: value,
                    };
                  }
                  return problem;
                })
              : [{ [key]: value }],
        };
      } else if (type === "goal") {
        data = {
          ...data,
          problems: data.problems.map((problem, tempProblemIndex) => {
            if (tempProblemIndex === problemIndex) {
              return {
                ...problem,
                goals:
                  problem.goals?.length > 0
                    ? problem.goals.map((goal, tempGoalIndex) => {
                        if (tempGoalIndex === goalIndex) {
                          return {
                            ...goal,
                            [key]: value,
                          };
                        }
                        return goal;
                      })
                    : [{ [key]: value }],
              };
            }
            return problem;
          }),
        };
      } else if (type === "objective") {
        data = {
          ...data,
          problems: data.problems.map((problem, tempProblemIndex) => {
            if (tempProblemIndex === problemIndex) {
              return {
                ...problem,
                goals: problem.goals.map((goal, tempGoalIndex) => {
                  if (tempGoalIndex === goalIndex) {
                    return {
                      ...goal,
                      objectives:
                        goal?.objectives?.length > 0
                          ? goal.objectives.map((objective, tempObjectiveIndex) => {
                              if (tempObjectiveIndex === objectiveIndex) {
                                return {
                                  ...objective,
                                  [key]: value,
                                };
                              }
                              return objective;
                            })
                          : [{ [key]: value }],
                    };
                  }
                  return goal;
                }),
              };
            }
            return problem;
          }),
        };
      } else if (type === "intervention") {
        data = {
          ...data,
          problems: data.problems.map((problem, tempProblemIndex) => {
            if (tempProblemIndex === problemIndex) {
              return {
                ...problem,
                goals: problem.goals.map((goal, tempGoalIndex) => {
                  if (tempGoalIndex === goalIndex) {
                    return {
                      ...goal,
                      objectives: goal.objectives.map((objective, tempObjectiveIndex) => {
                        if (tempObjectiveIndex === objectiveIndex) {
                          return {
                            ...objective,
                            interventions:
                              objective?.interventions?.length > 0
                                ? objective.interventions.map(
                                    (intervention, tempInterventionIndex) => {
                                      if (tempInterventionIndex === interventionIndex) {
                                        return {
                                          ...intervention,
                                          [key]: value,
                                        };
                                      }
                                      return intervention;
                                    }
                                  )
                                : [{ [key]: value }],
                          };
                        }
                        return objective;
                      }),
                    };
                  }
                  return goal;
                }),
              };
            }
            return problem;
          }),
        };
      }
      return data;
    });
  };
  const addNewProblem = () => {
    setTreatmentPlanData(prevData => {
      return {
        ...prevData,
        problems:
          prevData.problems?.length > 0
            ? [...prevData.problems, getNewProblem(prevData.problems.length)]
            : [getNewProblem(0)],
      };
    });
  };
  const deleteProblem = problemIndex => {
    setTreatmentPlanData(prevData => {
      return {
        ...prevData,
        problems:
          prevData.problems?.filter(
            (problem, tempProblemIndex) => tempProblemIndex !== problemIndex
          ) || [],
      };
    });
  };
  const addNewGoal = problemIndex => {
    setTreatmentPlanData(prevData => {
      return {
        ...prevData,
        problems: prevData.problems.map((problem, tempProblemIndex) => {
          if (tempProblemIndex === problemIndex) {
            return {
              ...problem,
              goals:
                problem.goals?.length > 0
                  ? [...problem.goals, getNewGoal(problemIndex, problem.goals.length)]
                  : [getNewGoal(problemIndex, 0)],
            };
          }
          return problem;
        }),
      };
    });
  };
  const deleteGoal = (problemIndex, goalIndex) => {
    setTreatmentPlanData(prevData => {
      return {
        ...prevData,
        problems: prevData.problems.map((problem, tempProblemIndex) => {
          if (tempProblemIndex === problemIndex) {
            return {
              ...problem,
              goals:
                problem.goals?.filter((goal, tempGoalIndex) => tempGoalIndex !== goalIndex) || [],
            };
          }
          return problem;
        }),
      };
    });
  };
  const addNewObjective = (problemIndex, goalIndex) => {
    setTreatmentPlanData(prevData => {
      return {
        ...prevData,
        problems: prevData.problems.map((problem, tempProblemIndex) => {
          if (tempProblemIndex === problemIndex) {
            return {
              ...problem,
              goals: problem.goals.map((goal, tempGoalIndex) => {
                if (tempGoalIndex === goalIndex) {
                  return {
                    ...goal,
                    objectives:
                      goal.objectives?.length > 0
                        ? [
                            ...goal.objectives,
                            getNewObjective(problemIndex, goalIndex, goal.objectives.length),
                          ]
                        : [getNewObjective(problemIndex, goalIndex, 0)],
                  };
                }
                return goal;
              }),
            };
          }
          return problem;
        }),
      };
    });
  };
  const deleteObjective = (problemIndex, goalIndex, objectiveIndex) => {
    setTreatmentPlanData(prevData => {
      return {
        ...prevData,
        problems: prevData.problems.map((problem, tempProblemIndex) => {
          if (tempProblemIndex === problemIndex) {
            return {
              ...problem,
              goals: problem.goals.map((goal, tempGoalIndex) => {
                if (tempGoalIndex === goalIndex) {
                  return {
                    ...goal,
                    objectives:
                      goal.objectives?.filter(
                        (objective, tempObjectiveIndex) => tempObjectiveIndex !== objectiveIndex
                      ) || [],
                  };
                }
                return goal;
              }),
            };
          }
          return problem;
        }),
      };
    });
  };
  const addNewIntervention = (problemIndex, goalIndex, objectiveIndex) => {
    setTreatmentPlanData(prevData => {
      return {
        ...prevData,
        problems: prevData.problems.map((problem, tempProblemIndex) => {
          if (tempProblemIndex === problemIndex) {
            return {
              ...problem,
              goals: problem.goals.map((goal, tempGoalIndex) => {
                if (tempGoalIndex === goalIndex) {
                  return {
                    ...goal,
                    objectives: goal.objectives.map((objective, tempObjectiveIndex) => {
                      if (tempObjectiveIndex === objectiveIndex) {
                        return {
                          ...objective,
                          interventions:
                            objective.interventions?.length > 0
                              ? [
                                  ...objective.interventions,
                                  getNewIntervention(
                                    problemIndex,
                                    goalIndex,
                                    objectiveIndex,
                                    objective.interventions.length
                                  ),
                                ]
                              : [getNewObjective(problemIndex, goalIndex, objectiveIndex, 0)],
                        };
                      }
                      return objective;
                    }),
                  };
                }
                return goal;
              }),
            };
          }
          return problem;
        }),
      };
    });
  };
  const deleteIntervention = (problemIndex, goalIndex, objectiveIndex, interventionIndex) => {
    setTreatmentPlanData(prevData => {
      return {
        ...prevData,
        problems: prevData.problems.map((problem, tempProblemIndex) => {
          if (tempProblemIndex === problemIndex) {
            return {
              ...problem,
              goals: problem.goals.map((goal, tempGoalIndex) => {
                if (tempGoalIndex === goalIndex) {
                  return {
                    ...goal,
                    objectives: goal.objectives.map((objective, tempObjectiveIndex) => {
                      if (tempObjectiveIndex === objectiveIndex) {
                        return {
                          ...objective,
                          interventions:
                            objective.interventions?.filter(
                              (intervention, tempInterventionIndex) =>
                                tempInterventionIndex !== interventionIndex
                            ) || [],
                        };
                      }
                      return objective;
                    }),
                  };
                }
                return goal;
              }),
            };
          }
          return problem;
        }),
      };
    });
  };
  const saveTreatmentPlan = async () => {
    let payload = props.treatmentPlan?.data
      ? {
          ...props.treatmentPlan.data,
          revisedByPractitionerRoleId: props.selectedUserRole?.id,
          patientId: props.patientId,
          plan: treatmentPlanData,
          updatedAt: new Date().toISOString(),
        }
      : {
          revisedByPractitionerRoleId: props.selectedUserRole?.id,
          patientId: props.patientId,
          plan: treatmentPlanData,
        };
    console.log("payload", payload);
    await props.generateTreatmentPlan({
      patientId: props.patientId,
      payload,
      isEdit: !!props.treatmentPlan?.data,
    });
    props.toggleModal(false);
  };
  return (
    <Modal
      show={props.modalOpen}
      onHide={() => props.toggleModal(false)}
      className="treatment-plan-modal"
    >
      <Modal.Header closeButton>
        <Modal.Title>Treatment Plan</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <ProgressLoader loading={props.treatmentPlan?.loading}>
          <div className="treatment-plan-modal-body">
            {treatmentPlanData?.["problems"]?.length > 0 && (
              <div className="treatment-plan-problems-container">
                <ol className="treatment-plan-problems-list">
                  {treatmentPlanData?.["problems"].map((problem, problemIndex) => {
                    return (
                      <li
                        className="treatment-plan-problem-item"
                        key={problem.problem_id}
                      >
                        <div className="treatment-plan-input-container with-textarea">
                          <div className="treatment-plan-input-label">
                            Problem:
                            {treatmentPlanData?.["problems"]?.length > 1 && (
                              <OverlayTrigger
                                overlay={<Tooltip>Delete Problem</Tooltip>}
                                placement="top"
                              >
                                <div
                                  className="delete-icon"
                                  onClick={() => deleteProblem(problemIndex)}
                                >
                                  <img
                                    src={DeleteIcon}
                                    alt=""
                                  />
                                </div>
                              </OverlayTrigger>
                            )}
                          </div>
                          <textarea
                            className="treatment-plan-input"
                            type="text"
                            value={problem.description}
                            placeholder="Problem Description"
                            onChange={e =>
                              onInputChange("description", e.target.value, "problem", problemIndex)
                            }
                          />
                        </div>
                        {/* <div className="treatment-plan-input-container with-textarea">
                          <div className="treatment-plan-input-label">
                            {inputMapping["behavioral_description"].label}:
                          </div>
                          <textarea
                            className="treatment-plan-input"
                            value={problem.behavioral_description}
                            placeholder={inputMapping["behavioral_description"].placeholder}
                            onChange={e =>
                              onInputChange(
                                "behavioral_description",
                                e.target.value,
                                "problem",
                                problemIndex
                              )
                            }
                          />
                        </div> */}
                        {/* <div className="treatment-plan-input-container with-textarea">
                          <div className="treatment-plan-input-label">
                            {inputMapping["patient_description"].label}:
                          </div>
                          <textarea
                            className="treatment-plan-input"
                            value={problem.patient_description}
                            placeholder={inputMapping["patient_description"].placeholder}
                            onChange={e =>
                              onInputChange(
                                "patient_description",
                                e.target.value,
                                "problem",
                                problemIndex
                              )
                            }
                          />
                        </div> */}
                        {/* <div className="treatment-plan-input-container with-textarea">
                          <div className="treatment-plan-input-label">
                            {inputMapping["strength"].label}:
                          </div>
                          <textarea
                            className="treatment-plan-input"
                            value={problem.strength}
                            placeholder={inputMapping["strength"].placeholder}
                            onChange={e =>
                              onInputChange("strength", e.target.value, "problem", problemIndex)
                            }
                          />
                        </div> */}
                        {/* <div className="treatment-plan-input-container with-textarea">
                          <div className="treatment-plan-input-label">
                            {inputMapping["comment"].label}:
                          </div>
                          <textarea
                            className="treatment-plan-input"
                            value={problem.comment}
                            placeholder={inputMapping["comment"].placeholder}
                            onChange={e =>
                              onInputChange("comment", e.target.value, "problem", problemIndex)
                            }
                          />
                        </div> */}
                        {problem?.goals?.length > 0 && (
                          <div className="treatment-plan-goals-container">
                            <div className="treatment-plan-goals-label">Goals:</div>
                            <ol className="treatment-plan-goals-list">
                              {problem.goals.map((goal, goalIndex) => {
                                return (
                                  <li
                                    className="treatment-plan-goal-item"
                                    key={goal.goal_id}
                                  >
                                    <div className="treatment-plan-input-container with-textarea">
                                      <div className="treatment-plan-input-label">
                                        Goal:
                                        {problem?.goals?.length > 1 && (
                                          <OverlayTrigger
                                            overlay={<Tooltip>Delete Goal</Tooltip>}
                                            placement="top"
                                          >
                                            <div
                                              className="delete-icon"
                                              onClick={() => deleteGoal(problemIndex, goalIndex)}
                                            >
                                              <img
                                                src={DeleteIcon}
                                                alt=""
                                              />
                                            </div>
                                          </OverlayTrigger>
                                        )}
                                      </div>
                                      <textarea
                                        className="treatment-plan-input"
                                        type="text"
                                        value={goal.description}
                                        placeholder="Goal Description"
                                        onChange={e =>
                                          onInputChange(
                                            "description",
                                            e.target.value,
                                            "goal",
                                            problemIndex,
                                            goalIndex
                                          )
                                        }
                                      />
                                    </div>
                                    <div className="treatment-plan-input-container">
                                      <div className="treatment-plan-input-label">
                                        {inputMapping["responsible_party"].label}:
                                      </div>
                                      <div className="treatment-plan-input-options">
                                        {inputMapping["responsible_party"].options.map(option => {
                                          return (
                                            <div
                                              className="treatment-plan-input-option"
                                              key={option}
                                            >
                                              <Form.Check
                                                type="radio"
                                                label={
                                                  <div className="treatment-plan-input-option-label">
                                                    {option}
                                                  </div>
                                                }
                                                id={`treatment-plan-input-option-${option}-${problemIndex}-${goalIndex}`}
                                                checked={goal.responsible_party === option}
                                                onChange={() =>
                                                  onInputChange(
                                                    "responsible_party",
                                                    option,
                                                    "goal",
                                                    problemIndex,
                                                    goalIndex
                                                  )
                                                }
                                                className="treatment-plan-input-option-radio-button"
                                              />
                                            </div>
                                          );
                                        })}
                                      </div>
                                    </div>
                                    <div className="treatment-plan-goal-input-list">
                                      <div className="treatment-plan-input-container">
                                        <div className="treatment-plan-input-label">
                                          {inputMapping["target_date"].label}:
                                        </div>
                                        <div
                                          className="treatment-plan-input-date-container"
                                          ref={ref =>
                                            (goalCalendarRef.current[
                                              `${problemIndex}-${goalIndex}`
                                            ] = ref)
                                          }
                                          onClick={() =>
                                            setShowGoalCalendar(prevData => {
                                              return {
                                                ...prevData,
                                                [`${problemIndex}-${goalIndex}`]:
                                                  !prevData?.[`${problemIndex}-${goalIndex}`],
                                              };
                                            })
                                          }
                                        >
                                          <div className="treatment-plan-input-date">
                                            {goal.target_date
                                              ? moment(goal.target_date).format("MM/DD/YYYY")
                                              : inputMapping["target_date"].placeholder}
                                          </div>
                                        </div>
                                        <Overlay
                                          target={
                                            goalCalendarRef.current[`${problemIndex}-${goalIndex}`]
                                          }
                                          show={
                                            !!showGoalCalendar?.[`${problemIndex}-${goalIndex}`]
                                          }
                                          placement="top"
                                          rootClose={true}
                                        >
                                          {props => (
                                            <Tooltip
                                              id="calendar-overlay"
                                              className="calendar-overlay"
                                              {...props}
                                            >
                                              <Calendar
                                                onChange={date =>
                                                  onInputChange(
                                                    "target_date",
                                                    date,
                                                    "goal",
                                                    problemIndex,
                                                    goalIndex
                                                  )
                                                }
                                                value={goal.target_date}
                                                minDate={new Date()}
                                              />
                                            </Tooltip>
                                          )}
                                        </Overlay>
                                      </div>
                                      <div className="treatment-plan-input-container">
                                        <div className="treatment-plan-input-label">
                                          {inputMapping["target_number_of_sessions"].label}:
                                        </div>
                                        <input
                                          className="treatment-plan-input"
                                          value={goal.target_number_of_sessions}
                                          placeholder={
                                            inputMapping["target_number_of_sessions"].placeholder
                                          }
                                          type={inputMapping["target_number_of_sessions"].type}
                                          onChange={e =>
                                            onInputChange(
                                              "target_number_of_sessions",
                                              e.target.value,
                                              "goal",
                                              problemIndex,
                                              goalIndex
                                            )
                                          }
                                        />
                                      </div>
                                    </div>
                                    {/* <div className="treatment-plan-input-container with-textarea">
                                      <div className="treatment-plan-input-label">
                                        {inputMapping["patient_description"].label}:
                                      </div>
                                      <textarea
                                        className="treatment-plan-input"
                                        value={goal.patient_description}
                                        placeholder={
                                          inputMapping["patient_description"].placeholder
                                        }
                                        onChange={e =>
                                          onInputChange(
                                            "patient_description",
                                            e.target.value,
                                            "goal",
                                            problemIndex,
                                            goalIndex
                                          )
                                        }
                                      />
                                    </div> */}
                                    {/* <div className="treatment-plan-input-container with-textarea">
                                      <div className="treatment-plan-input-label">
                                        {inputMapping["comment"].label}:
                                      </div>
                                      <textarea
                                        className="treatment-plan-input"
                                        value={goal.comment}
                                        placeholder={inputMapping["comment"].placeholder}
                                        onChange={e =>
                                          onInputChange(
                                            "comment",
                                            e.target.value,
                                            "goal",
                                            problemIndex,
                                            goalIndex
                                          )
                                        }
                                      />
                                    </div> */}
                                    {goal?.objectives?.length > 0 && (
                                      <div className="treatment-plan-objectives-container">
                                        <div className="treatment-plan-objectives-label">
                                          Objectives:
                                        </div>
                                        <ol className="treatment-plan-objectives-list">
                                          {goal.objectives.map((objective, objectiveIndex) => {
                                            return (
                                              <li
                                                className="treatment-plan-objective-item"
                                                key={objective.objective_id}
                                              >
                                                <div className="treatment-plan-input-container with-textarea">
                                                  <div className="treatment-plan-input-label">
                                                    Objective:
                                                    {goal?.objectives?.length > 1 && (
                                                      <OverlayTrigger
                                                        overlay={
                                                          <Tooltip>Delete Objective</Tooltip>
                                                        }
                                                        placement="top"
                                                      >
                                                        <div
                                                          className="delete-icon"
                                                          onClick={() =>
                                                            deleteObjective(
                                                              problemIndex,
                                                              goalIndex,
                                                              objectiveIndex
                                                            )
                                                          }
                                                        >
                                                          <img
                                                            src={DeleteIcon}
                                                            alt=""
                                                          />
                                                        </div>
                                                      </OverlayTrigger>
                                                    )}
                                                  </div>
                                                  <textarea
                                                    className="treatment-plan-input"
                                                    type="text"
                                                    value={objective.description}
                                                    placeholder="Objective Description"
                                                    onChange={e =>
                                                      onInputChange(
                                                        "description",
                                                        e.target.value,
                                                        "objective",
                                                        problemIndex,
                                                        goalIndex,
                                                        objectiveIndex
                                                      )
                                                    }
                                                  />
                                                </div>
                                                {/* <div className="treatment-plan-input-container">
                                                  <div className="treatment-plan-input-label">
                                                    {inputMapping["target_date"].label}:
                                                  </div>
                                                  <div
                                                    className="treatment-plan-input-date-container"
                                                    ref={ref =>
                                                      (objectiveCalendarRef.current[
                                                        `${problemIndex}-${goalIndex}-${objectiveIndex}`
                                                      ] = ref)
                                                    }
                                                    onClick={() =>
                                                      setShowObjectiveCalendar(prevData => {
                                                        return {
                                                          ...prevData,
                                                          [`${problemIndex}-${goalIndex}-${objectiveIndex}`]:
                                                            !prevData?.[
                                                              `${problemIndex}-${goalIndex}-${objectiveIndex}`
                                                            ],
                                                        };
                                                      })
                                                    }
                                                  >
                                                    <div className="treatment-plan-input-date">
                                                      {objective.target_date
                                                        ? moment(objective.target_date).format(
                                                            "MM/DD/YYYY"
                                                          )
                                                        : inputMapping["target_date"].placeholder}
                                                    </div>
                                                  </div>
                                                  <Overlay
                                                    target={
                                                      objectiveCalendarRef.current[
                                                        `${problemIndex}-${goalIndex}-${objectiveIndex}`
                                                      ]
                                                    }
                                                    show={
                                                      !!showObjectiveCalendar?.[
                                                        `${problemIndex}-${goalIndex}-${objectiveIndex}`
                                                      ]
                                                    }
                                                    placement="top"
                                                    rootClose={true}
                                                  >
                                                    {props => (
                                                      <Tooltip
                                                        id="calendar-overlay"
                                                        className="calendar-overlay"
                                                        {...props}
                                                      >
                                                        <Calendar
                                                          onChange={date =>
                                                            onInputChange(
                                                              "target_date",
                                                              date,
                                                              "objective",
                                                              problemIndex,
                                                              goalIndex,
                                                              objectiveIndex
                                                            )
                                                          }
                                                          value={objective.target_date}
                                                          minDate={new Date()}
                                                        />
                                                      </Tooltip>
                                                    )}
                                                  </Overlay>
                                                </div> */}
                                                {/* <div className="treatment-plan-input-container">
                                                  <div className="treatment-plan-input-label">
                                                    {
                                                      inputMapping["target_number_of_sessions"]
                                                        .label
                                                    }
                                                    :
                                                  </div>
                                                  <input
                                                    className="treatment-plan-input"
                                                    value={objective.target_number_of_sessions}
                                                    placeholder={
                                                      inputMapping["target_number_of_sessions"]
                                                        .placeholder
                                                    }
                                                    type={
                                                      inputMapping["target_number_of_sessions"].type
                                                    }
                                                    onChange={e =>
                                                      onInputChange(
                                                        "target_number_of_sessions",
                                                        e.target.value,
                                                        "objective",
                                                        problemIndex,
                                                        goalIndex,
                                                        objectiveIndex
                                                      )
                                                    }
                                                  />
                                                </div> */}
                                                {/* <div className="treatment-plan-input-container with-textarea">
                                                  <div className="treatment-plan-input-label">
                                                    {inputMapping["patient_description"].label}:
                                                  </div>
                                                  <textarea
                                                    className="treatment-plan-input"
                                                    value={objective.patient_description}
                                                    placeholder={
                                                      inputMapping["patient_description"]
                                                        .placeholder
                                                    }
                                                    onChange={e =>
                                                      onInputChange(
                                                        "patient_description",
                                                        e.target.value,
                                                        "objective",
                                                        problemIndex,
                                                        goalIndex,
                                                        objectiveIndex
                                                      )
                                                    }
                                                  />
                                                </div> */}
                                                {/* <div className="treatment-plan-input-container with-textarea">
                                                  <div className="treatment-plan-input-label">
                                                    {inputMapping["comment"].label}:
                                                  </div>
                                                  <textarea
                                                    className="treatment-plan-input"
                                                    value={objective.comment}
                                                    placeholder={
                                                      inputMapping["comment"].placeholder
                                                    }
                                                    onChange={e =>
                                                      onInputChange(
                                                        "comment",
                                                        e.target.value,
                                                        "objective",
                                                        problemIndex,
                                                        goalIndex,
                                                        objectiveIndex
                                                      )
                                                    }
                                                  />
                                                </div> */}
                                                {objective?.interventions?.length > 0 && (
                                                  <div className="treatment-plan-interventions-container">
                                                    <div className="treatment-plan-interventions-label">
                                                      Interventions:
                                                    </div>
                                                    <ol className="treatment-plan-interventions-list">
                                                      {objective.interventions.map(
                                                        (intervention, interventionIndex) => {
                                                          return (
                                                            <li
                                                              className="treatment-plan-intervention-item"
                                                              key={intervention.intervention_id}
                                                            >
                                                              <div className="treatment-plan-input-container with-textarea">
                                                                <div className="treatment-plan-input-label">
                                                                  Intervention:
                                                                  {objective?.interventions
                                                                    ?.length > 1 && (
                                                                    <OverlayTrigger
                                                                      overlay={
                                                                        <Tooltip>
                                                                          Delete Intervention
                                                                        </Tooltip>
                                                                      }
                                                                      placement="top"
                                                                    >
                                                                      <div
                                                                        className="delete-icon"
                                                                        onClick={() =>
                                                                          deleteIntervention(
                                                                            problemIndex,
                                                                            goalIndex,
                                                                            objectiveIndex,
                                                                            interventionIndex
                                                                          )
                                                                        }
                                                                      >
                                                                        <img
                                                                          src={DeleteIcon}
                                                                          alt=""
                                                                        />
                                                                      </div>
                                                                    </OverlayTrigger>
                                                                  )}
                                                                </div>
                                                                <textarea
                                                                  className="treatment-plan-input"
                                                                  type="text"
                                                                  value={intervention.description}
                                                                  placeholder="Intervention Description"
                                                                  onChange={e =>
                                                                    onInputChange(
                                                                      "description",
                                                                      e.target.value,
                                                                      "intervention",
                                                                      problemIndex,
                                                                      goalIndex,
                                                                      objectiveIndex,
                                                                      interventionIndex
                                                                    )
                                                                  }
                                                                />
                                                              </div>
                                                              <div className="treatment-plan-intervention-input-list">
                                                                <div className="treatment-plan-input-container">
                                                                  <div className="treatment-plan-input-label">
                                                                    {
                                                                      inputMapping["frequency"]
                                                                        .label
                                                                    }
                                                                    :
                                                                  </div>
                                                                  <input
                                                                    className="treatment-plan-input"
                                                                    value={intervention.frequency}
                                                                    placeholder={
                                                                      inputMapping["frequency"]
                                                                        .placeholder
                                                                    }
                                                                    type={
                                                                      inputMapping["frequency"].type
                                                                    }
                                                                    onChange={e =>
                                                                      onInputChange(
                                                                        "frequency",
                                                                        e.target.value,
                                                                        "intervention",
                                                                        problemIndex,
                                                                        goalIndex,
                                                                        objectiveIndex,
                                                                        interventionIndex
                                                                      )
                                                                    }
                                                                  />
                                                                </div>
                                                                <div className="treatment-plan-input-container">
                                                                  <div className="treatment-plan-input-label">
                                                                    {inputMapping["lengths"].label}:
                                                                  </div>
                                                                  <input
                                                                    className="treatment-plan-input"
                                                                    value={intervention.lengths}
                                                                    placeholder={
                                                                      inputMapping["lengths"]
                                                                        .placeholder
                                                                    }
                                                                    type={
                                                                      inputMapping["lengths"].type
                                                                    }
                                                                    onChange={e =>
                                                                      onInputChange(
                                                                        "lengths",
                                                                        e.target.value,
                                                                        "intervention",
                                                                        problemIndex,
                                                                        goalIndex,
                                                                        objectiveIndex,
                                                                        interventionIndex
                                                                      )
                                                                    }
                                                                  />
                                                                </div>
                                                                <div className="treatment-plan-input-container">
                                                                  <div className="treatment-plan-input-label">
                                                                    {inputMapping["duration"].label}
                                                                    :
                                                                  </div>
                                                                  <input
                                                                    className="treatment-plan-input"
                                                                    value={intervention.duration}
                                                                    placeholder={
                                                                      inputMapping["duration"]
                                                                        .placeholder
                                                                    }
                                                                    type={
                                                                      inputMapping["duration"].type
                                                                    }
                                                                    onChange={e =>
                                                                      onInputChange(
                                                                        "duration",
                                                                        e.target.value,
                                                                        "intervention",
                                                                        problemIndex,
                                                                        goalIndex,
                                                                        objectiveIndex,
                                                                        interventionIndex
                                                                      )
                                                                    }
                                                                  />
                                                                </div>
                                                              </div>
                                                              {/* <div className="treatment-plan-input-container with-textarea">
                                                                <div className="treatment-plan-input-label">
                                                                  {inputMapping["comment"].label}:
                                                                </div>
                                                                <textarea
                                                                  className="treatment-plan-input"
                                                                  value={intervention.comment}
                                                                  placeholder={
                                                                    inputMapping["comment"]
                                                                      .placeholder
                                                                  }
                                                                  onChange={e =>
                                                                    onInputChange(
                                                                      "comment",
                                                                      e.target.value,
                                                                      "intervention",
                                                                      problemIndex,
                                                                      goalIndex,
                                                                      objectiveIndex,
                                                                      interventionIndex
                                                                    )
                                                                  }
                                                                />
                                                              </div> */}
                                                            </li>
                                                          );
                                                        }
                                                      )}
                                                    </ol>
                                                    <div
                                                      className="add-item-button"
                                                      onClick={() =>
                                                        addNewIntervention(
                                                          problemIndex,
                                                          goalIndex,
                                                          objectiveIndex
                                                        )
                                                      }
                                                    >
                                                      Add New Intervention
                                                    </div>
                                                  </div>
                                                )}
                                              </li>
                                            );
                                          })}
                                        </ol>
                                        <div
                                          className="add-item-button"
                                          onClick={() => addNewObjective(problemIndex, goalIndex)}
                                        >
                                          Add New Objective
                                        </div>
                                      </div>
                                    )}
                                  </li>
                                );
                              })}
                            </ol>
                            <div
                              className="add-item-button"
                              onClick={() => addNewGoal(problemIndex)}
                            >
                              Add New Goal
                            </div>
                          </div>
                        )}
                      </li>
                    );
                  })}
                </ol>
                <div
                  className="add-item-button"
                  onClick={() => addNewProblem()}
                >
                  Add New Problem
                </div>
              </div>
            )}
          </div>
        </ProgressLoader>
      </Modal.Body>
      <Modal.Footer>
        <SecondaryButton onClick={() => props.toggleModal(false)}>Cancel</SecondaryButton>
        <PrimaryButton onClick={() => saveTreatmentPlan()}>Save Changes</PrimaryButton>
      </Modal.Footer>
    </Modal>
  );
};

const ProgressLoader = props => {
  if (props.loading) {
    return (
      <div className="progress-loader">
        <ProgressBar
          animated
          now={100}
        />
        <div className="loading-msg">Generating Treatment Plan</div>
      </div>
    );
  } else {
    return props.children || <></>;
  }
};
