import React, { useEffect, useState } from "react";
import { useNavigate, useParams, useBlocker } from "react-router-dom";
import { useDyteMeeting, useDyteSelector } from "@dytesdk/react-web-core";
import {
  provideDyteDesignSystem,
  DyteDialogManager,
  DyteNotifications,
} from "@dytesdk/react-ui-kit";
import JoinSession from "./components/JoinSession";
import SessionJoined from "./components/SessionJoined";
import SessionLeft from "./components/SessionLeft";
import SessionEnded from "./components/SessionEnded";
import { AlertModal, Loader } from "@/components";
import designTokens from "./designTokens.json";
import { Form } from "react-bootstrap";
import VirtualIcon from "@/assets/video-recorder.svg";
import PhoneSpeakerIcon from "@/assets/phone-speaker.svg";
import { sendDelayMessage } from "@/Routes/Appointments/appointmentsModule";
import "./Meeting.scss";
import MentalWellnessScore from "@/Routes/Appointments/components/Sessions/components/MentalWellnessScore";
import { sendMeetingJoinedEvent } from "@/Routes/Appointments/appointmentsModule";
import WaitingBar from "./components/WaitingBar";

// const groupId = import.meta.env.VITE_GROUP_ID;
const isMobile = window.innerWidth <= 480;

function Meeting(props) {
  const { meeting } = useDyteMeeting();
  const navigate = useNavigate();
  const { sessionCategory, patientId, sessionId } = useParams();
  const [showWarning, setShowWarning] = useState(false);
  const roomState = useDyteSelector(meeting => meeting.self.roomState);
  useEffect(() => {
    provideDyteDesignSystem(document.body, designTokens);
    console.log("meeting => ", meeting);
  }, []);
  useEffect(() => {
    if (roomState === "left" || roomState === "ended") {
      createTask();
      props.hideSideBar(isMobile ? true : false);
    } else {
      props.hideSideBar(true);
    }
    if (roomState === "ended") {
      // navigate(`/session-details/${sessionCategory}/virtual/${sessionId}/${patientId}`, {replace: true});
      let options = {
        patientId,
        groupId: patientId,
        isGroupSession: sessionCategory === "group",
        message: "",
      };
      sendDelayMessage(options);
    }
    if (roomState === "joined") {
      sendMeetingJoinedEvent({
        isGroupSession: sessionCategory === "group",
        patientId,
        groupId: patientId,
      });
    }
    console.log("roomState => ", roomState);
  }, [roomState]);
  const createTask = () => {
    props.addTask(props.sessionDetailsData);
  };
  const leaveSession = async () => {
    try {
      // if (roomState !== "joined") {
      //   await meeting?.joinRoom();
      // }
      await meeting?.participants?.kickAll();
      // await meeting?.leave("ended");
    } catch (error) {
      console.log(error);
    }
  };
  const navBlocker = useBlocker(
    ({ currentLocation, nextLocation, historyAction }) =>
      roomState === "joined" &&
      currentLocation.pathname !== nextLocation.pathname &&
      historyAction === "POP"
  );
  return (
    <div className="session-meeting-container">
      <WaitingBar />
      {!!props.sideBarHidden && (
        <div className="session-exit-btn-container">
          {/* <div
            className="session-exit-btn"
            onClick={() => setShowWarning(true)}
          >
            Exit
          </div> */}
          {isMobile && roomState !== "joined" ? (
            <div
              className="session-exit-btn"
              onClick={() => setShowWarning(true)}
            >
              Exit
            </div>
          ) : (
            <div></div>
          )}
          <div></div>
          <MentalWellnessScore session={props.sessionDetailsData} />
        </div>
      )}
      <DyteDialogManager meeting={meeting} />
      <DyteNotifications
        meeting={meeting}
        config={{
          config: {
            // which notifications to show
            notifications: ["chat", "participant_joined", "participant_left"],
            // which notifications should have sounds
            notification_sounds: ["chat", "participant_joined", "participant_left"],
            // maximum number of participant joined sound notifications
            participant_joined_sound_notification_limit: 10,
            // maximum number of chat message sound notifications
            participant_chat_message_sound_notification_limit: 10,
          },
        }}
      />
      {roomState === "init" && (
        <Loader loading={props.sessionType === "in-person"}>
          <JoinSession
            patientId={patientId}
            sessionId={sessionId}
            groupId={patientId}
            sessionCategory={sessionCategory}
            sessionDetailsData={props.sessionDetailsData}
            sessionType={props.sessionType}
            meetingToken={props.meetingToken}
            initMeeting={props.initMeeting}
            preferredTimezone={props.preferredTimezone}
          />
        </Loader>
      )}
      {roomState === "joined" && (
        <SessionJoined
          setSelectedMember={props.setSelectedMember}
          showMemberNotes={props.showMemberNotes}
          setShowNotes={props.setShowNotes}
          showNotes={props.showNotes}
          sessionDetailsData={props.sessionDetailsData}
          preferredTimezone={props.preferredTimezone}
          mapProviderSessionId={props.mapProviderSessionId}
          meetingSession={props.meetingSession}
          changeMeetingSession={props.changeMeetingSession}
          sessionType={props.sessionType}
        />
      )}
      {roomState === "left" && (
        <SessionLeft
          meetingToken={props.meetingToken}
          initMeeting={props.initMeeting}
          sessionDetailsData={props.sessionDetailsData}
          sessionType={props.sessionType}
        />
      )}
      {roomState === "ended" && (
        <SessionEnded
          meetingToken={props.meetingToken}
          initMeeting={props.initMeeting}
          sessionDetailsData={props.sessionDetailsData}
          sessionType={props.sessionType}
        />
      )}
      <AlertModal
        modalOpen={navBlocker.state === "blocked" || showWarning}
        onCancel={() => {
          if (showWarning) {
            setShowWarning(false);
          } else {
            navBlocker.reset();
          }
        }}
        onConfirm={async () => {
          await leaveSession();
          if (showWarning) {
            setShowWarning(false);
            // navigate(`/appointments`, { replace: true });
            window.location.href = window.location.origin + "/";
          } else {
            navBlocker.proceed();
          }
        }}
        title={"Leave the session?"}
        desc={"Session will get ended if you exit"}
        confirmBtnText={"End Session"}
        cancelBtnText={"Cancel"}
      />
    </div>
  );
}

export default Meeting;
