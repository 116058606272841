import WaitingBar from "./WaitingBar";
import { connect } from "react-redux";
import { getSessionsByPractitionerRole } from "@/Routes/Appointments/appointmentsModule";

const mapStateToProps = state => {
  return {
    sessions: state.appointments.sessions,
    selectedUserRole: state.user.selectedUserRole,
    patientStatus: state.appointments.sessions.patientStatus,
    groupStatus: state.appointments.sessions.groupStatus,
    preferredTimezone: state.user.preferredTimezone,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getSessionsByPractitionerRole: options => dispatch(getSessionsByPractitionerRole(options)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(WaitingBar);
