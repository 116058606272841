import React, { useState, useRef, useEffect } from "react";
import { ReactSVG } from "react-svg";
import { PrimaryButton, SecondaryButton, Loader } from "@/components";
import { Overlay, Tooltip } from "react-bootstrap";
import Calendar from "react-calendar";
import SessionCard from "./components/SessionCard";
import StartSessionModal from "../StartSessionModal";
import ScheduleSessionModal from "../ScheduleSessionModal";
import ClockIcon from "@/assets/clock.svg";
import CompletedSessionIcon from "@/assets/calendar-completed.svg";
import CalendarIcon from "@/assets/calendar.svg";
import moment from "moment-timezone";
import "./Sessions.scss";

const DATE_FORMAT = "MMMM DD, YYYY";

const APP_ENV = import.meta.env.VITE_APP_ENV || "PROD";

function Sessions(props) {
  const [activeTab, setActiveTab] = useState("upcoming");
  const [showStartSessionModal, setShowStartSessionModal] = useState(false);
  const [showScheduleSessionModal, setShowScheduleSessionModal] = useState(false);
  const calendarRef = useRef(null);
  const [showCalendar, setShowCalendar] = useState(false);
  const getDefaultSelectedDate = () => {
    const orgName = props.selectedUserRole?.organizationName;
    if (APP_ENV === "DEV" && orgName === "Soulside Inc") {
      return moment("2024-08-27T00:00:01.677-07:00").tz(
        props.preferredTimezone?.name || "America/Los_Angeles"
      );
    }
    return moment().tz(props.preferredTimezone?.name || "America/Los_Angeles");
  };
  const [selectedDate, setSelectedDate] = useState(getDefaultSelectedDate());
  const onSelectDate = date => {
    setSelectedDate(date);
    setShowCalendar(false);
  };
  // useEffect(() => {
  //   let blurFunc = e => {
  //     let sessionDateWrapper = document.querySelector(".session-date-wrapper");
  //     let calendarElem = document.querySelector("#calendar-overlay");
  //     if (
  //       e?.target !== calendarElem &&
  //       e?.target !== sessionDateWrapper &&
  //       !calendarElem?.contains(e?.target) &&
  //       !sessionDateWrapper?.contains(e?.target)
  //     ) {
  //       // setShowCalendar(false);
  //     }
  //   };
  //   document.addEventListener("click", blurFunc);
  //   return () => {
  //     document.removeEventListener("click", blurFunc);
  //   };
  // }, []);
  let dateFilteredSessions = props.sessions.data.filter(session => {
    if (!session.startTime) return false;
    const sessionDate = moment(session.startTime).tz(
      props.preferredTimezone?.name || "America/Los_Angeles"
    );
    const today = moment(selectedDate);
    return (
      sessionDate.year() === today.year() &&
      sessionDate.month() === today.month() &&
      sessionDate.date() === today.date()
    );
  });
  dateFilteredSessions = dateFilteredSessions.sort(
    (session1, session2) => new Date(session1.startTime) - new Date(session2.startTime)
  );
  let completedSessions = props.sessions.data.filter(session => {
    if (!session.startTime) return false;

    const sessionDate = new Date(
      new Date(session.startTime).toLocaleString("en", {
        timeZone: props.preferredTimezone?.name || "America/Los_Angeles",
      })
    );
    const today = new Date(
      new Date().toLocaleString("en", {
        timeZone: props.preferredTimezone?.name || "America/Los_Angeles",
      })
    );

    // Compare the full date (year, month, day) to exclude today's sessions
    return (
      sessionDate.getFullYear() < today.getFullYear() ||
      (sessionDate.getFullYear() === today.getFullYear() &&
        (sessionDate.getMonth() < today.getMonth() ||
          (sessionDate.getMonth() === today.getMonth() && sessionDate.getDate() < today.getDate())))
    );
    // return !!session.endTime && new Date(session.endTime) < new Date()
  });
  completedSessions = completedSessions.sort(
    (session1, session2) => new Date(session2.startTime) - new Date(session1.startTime)
  );
  let upcomingSessions = props.sessions.data.filter(session => {
    const sessionDate = new Date(
      new Date(session.startTime).toLocaleString("en", {
        timeZone: props.preferredTimezone?.name || "America/Los_Angeles",
      })
    );
    const today = new Date(
      new Date().toLocaleString("en", {
        timeZone: props.preferredTimezone?.name || "America/Los_Angeles",
      })
    );
    return !(
      sessionDate.getFullYear() < today.getFullYear() ||
      (sessionDate.getFullYear() === today.getFullYear() &&
        (sessionDate.getMonth() < today.getMonth() ||
          (sessionDate.getMonth() === today.getMonth() && sessionDate.getDate() < today.getDate())))
    );
  });
  upcomingSessions = upcomingSessions.sort(
    (session1, session2) => new Date(session1.startTime) - new Date(session2.startTime)
  );
  // const sessionList = activeTab === "upcoming" ? upcomingSessions : completedSessions;
  const sessionList = dateFilteredSessions;
  const onDateBackClick = () => {
    let newSelectedDate = moment(selectedDate)
      .tz(props.preferredTimezone?.name || "America/Los_Angeles")
      .subtract(1, "days");
    // let newSelectedDate = new Date(selectedDate);
    // let selectDateDay = newSelectedDate.getDate();
    // newSelectedDate.setDate(selectDateDay - 1);
    setSelectedDate(newSelectedDate);
  };
  const onDateNextClick = () => {
    let newSelectedDate = moment(selectedDate)
      .tz(props.preferredTimezone?.name || "America/Los_Angeles")
      .add(1, "days");
    // let newSelectedDate = new Date(selectedDate);
    // let selectDateDay = newSelectedDate.getDate();
    // newSelectedDate.setDate(selectDateDay + 1);
    setSelectedDate(newSelectedDate);
  };
  return (
    <div className="appointments-wrapper">
      <div className="session-header">
        {/* <div className="session-label"></div> */}
        {/* <div className="sessions-tab-container">
          <div
            className={`session-tab ${activeTab === "upcoming" ? "active" : ""}`}
            onClick={() => setActiveTab("upcoming")}
          >
            <ReactSVG
              src={ClockIcon}
              className="session-tab-icon"
            />
            <span className="session-tab-title">Today's Appointments</span>
          </div>
          <div
            className={`session-tab ${activeTab === "completed" ? "active" : ""}`}
            onClick={() => setActiveTab("completed")}
          >
            <ReactSVG
              src={CompletedSessionIcon}
              className="session-tab-icon"
            />
            <span className="session-tab-title">Previous Appointments</span>
          </div>
        </div> */}
        <div className="session-date-wrapper">
          <div
            className="date-left-arrow date-arrow"
            onClick={onDateBackClick}
          ></div>
          <div
            className="session-date-container"
            ref={calendarRef}
            onClick={() => setShowCalendar(!showCalendar)}
          >
            <div className="session-date">{moment(selectedDate).format(DATE_FORMAT)}</div>
          </div>
          <div
            className="date-right-arrow date-arrow"
            onClick={onDateNextClick}
          ></div>
          <Overlay
            target={calendarRef.current}
            show={showCalendar}
            placement="bottom"
            rootCloseEvent="mousedown"
          >
            {props => (
              <Tooltip
                id="calendar-overlay"
                className="calendar-overlay"
                {...props}
              >
                <Calendar
                  onChange={onSelectDate}
                  value={moment(selectedDate)}
                />
              </Tooltip>
            )}
          </Overlay>
        </div>
        <div className="session-header-actions">
          {props.selectedUserRole?.businessFunction !== "Clinical Care" && (
            <SecondaryButton
              className="schedule-session-btn"
              onClick={() => setShowScheduleSessionModal(true)}
            >
              <ReactSVG
                src={CalendarIcon}
                className="schedule-session-icon"
              />
              Schedule Session
            </SecondaryButton>
          )}
          {props.selectedUserRole?.businessFunction === "Clinical Care" && (
            <SecondaryButton
              onClick={() => setShowStartSessionModal(true)}
              className="start-instant-session-btn"
            >
              Start an Instant Session
            </SecondaryButton>
          )}
        </div>
      </div>
      <Loader loading={props.sessions.loading}>
        <div className="session-list-container">
          {sessionList?.length > 0 ? (
            <div className="sessions-list">
              {sessionList.map(session => {
                const sessionDate = new Date(
                  new Date(session.startTime).toLocaleString("en", {
                    timeZone: props.preferredTimezone?.name || "America/Los_Angeles",
                  })
                );
                const today = new Date(
                  new Date().toLocaleString("en", {
                    timeZone: props.preferredTimezone?.name || "America/Los_Angeles",
                  })
                );
                let isUpcoming = !(
                  sessionDate.getFullYear() < today.getFullYear() ||
                  (sessionDate.getFullYear() === today.getFullYear() &&
                    (sessionDate.getMonth() < today.getMonth() ||
                      (sessionDate.getMonth() === today.getMonth() &&
                        sessionDate.getDate() < today.getDate())))
                );
                let isCompleted =
                  sessionDate.getFullYear() < today.getFullYear() ||
                  (sessionDate.getFullYear() === today.getFullYear() &&
                    (sessionDate.getMonth() < today.getMonth() ||
                      (sessionDate.getMonth() === today.getMonth() &&
                        sessionDate.getDate() < today.getDate())));
                return (
                  <SessionCard
                    session={session}
                    isUpcoming={isUpcoming}
                    isCompleted={isCompleted}
                    key={session.id}
                    getSessionsByPractitionerRole={props.getSessionsByPractitionerRole}
                  />
                );
              })}
            </div>
          ) : (
            <div className="no-sessions-found-container">
              <div className="no-sessions-found">No Sessions Found</div>
              {props.selectedUserRole?.businessFunction === "Clinical Care" && (
                <PrimaryButton
                  onClick={() => setShowStartSessionModal(true)}
                  className="start-instant-session-btn"
                >
                  Start an Instant Session
                </PrimaryButton>
              )}
            </div>
          )}
        </div>
      </Loader>
      <StartSessionModal
        modalOpen={showStartSessionModal}
        toggleModal={setShowStartSessionModal}
        practitionerRoleId={props.practitionerRoleId}
        getSessionsByPractitionerRole={props.getSessionsByPractitionerRole}
      />
      <ScheduleSessionModal
        modalOpen={showScheduleSessionModal}
        toggleModal={setShowScheduleSessionModal}
        practitionerRoleId={props.practitionerRoleId}
        getSessionsByPractitionerRole={props.getSessionsByPractitionerRole}
        selectedUserRole={props.selectedUserRole}
      />
    </div>
  );
}

export default Sessions;
