import { get, post } from "@/services/api";
import { getLocalStorage } from "@/services/utils";
import moment from "moment-timezone";
import { toast } from "react-toastify";
import { io } from "socket.io-client";

const IN_SESSION_SOCKET_URL =
  import.meta.env.VITE_IN_SESSION_SOCKET_URL || "https://insession.prod.soulsidehealth.com";

let patientSocket = null;
let groupSocket = null;
let patientIdsForSocket = [];
let groupIdsForSocket = [];
let sessionSocketResetTimer = null;

export const getSessionsByPractitionerRole = options => {
  return async (dispatch, getState) => {
    let data = [];
    dispatch({ type: "TOGGLE_SESSIONS_LOADER", show: true });
    let state = getState();
    let practitionerRoleId = state.user.selectedUserRole?.id;
    let businessFunction = state.user.selectedUserRole?.businessFunction;
    let orgId = state.user.selectedUserRole?.organizationId;
    try {
      let url = `practitioner-role/individual-session/find-all-by-org/${orgId}`;
      if (businessFunction === "Clinical Care") {
        url = `practitioner-role/individual-session/find-all-by-practitioner-role/${practitionerRoleId}`;
      }
      let response = await get(url);
      if (response?.data?.length > 0) {
        data = response.data;
      }
    } catch (error) {}
    try {
      let url = `practitioner-role/sessions/find-all-by-org/${orgId}`;
      if (businessFunction === "Clinical Care") {
        url = `practitioner-role/sessions/find-all-by-practitioner-role/${practitionerRoleId}`;
      }
      let response = await get(url);
      if (response?.data?.length > 0) {
        data = [
          ...data,
          ...response.data.map(i => {
            return { ...i, sessionCategory: "GROUP" };
          }),
        ];
        // if (process.env.NODE_ENV !== "development") {

        // }
      }
    } catch (error) {}
    if (data?.length > 0) {
      dispatch(setupSessionSocket(data));
    }
    dispatch({ type: "ADD_SESSIONS_DATA", data });
    dispatch({ type: "TOGGLE_SESSIONS_LOADER", show: false });
    return data;
  };
};

const setupSessionSocket = sessions => {
  return async (dispatch, getState) => {
    let state = getState();
    let preferredTimezone = state.user.preferredTimezone;
    let todaysSession = sessions.filter(session => {
      const today = new Date(
        new Date().toLocaleString("en", {
          timeZone: preferredTimezone?.name || "America/Los_Angeles",
        })
      );
      today.setHours(0, 0, 0, 0);
      const dateToCheck = new Date(
        new Date(session.startTime).toLocaleString("en", {
          timeZone: preferredTimezone?.name || "America/Los_Angeles",
        })
      );
      dateToCheck.setHours(0, 0, 0, 0);
      return dateToCheck.getTime() === today.getTime();
    });
    todaysSession = todaysSession.sort(
      (session1, session2) => new Date(session1.startTime) - new Date(session2.startTime)
    );
    todaysSession.forEach(session => {
      if (session.sessionCategory === "INDIVIDUAL") {
        if (session.patientId && !patientIdsForSocket.includes(session.patientId)) {
          patientIdsForSocket.push(session.patientId);
        }
      } else {
        if (session.groupId && !groupIdsForSocket.includes(session.groupId)) {
          groupIdsForSocket.push(session.groupId);
        }
      }
    });
    await dispatch(setupPatientSocket({ patientIds: patientIdsForSocket }));
    await dispatch(setupGroupSocket({ groupIds: groupIdsForSocket }));
    let currentTime = moment().tz(preferredTimezone?.name || "America/Los_Angeles");
    let endOfDay = moment.tz(preferredTimezone?.name || "America/Los_Angeles").endOf("day");
    let secondsRemaining = endOfDay.diff(currentTime, "seconds") + 60;
    if (sessionSocketResetTimer) {
      clearTimeout(sessionSocketResetTimer);
    }
    sessionSocketResetTimer = setTimeout(() => {
      if (patientSocket) {
        patientSocket.emit("practitioner-role-leave-patient-rooms", patientIdsForSocket);
      }
      patientIdsForSocket = [];
      if (groupSocket) {
        groupSocket.emit("practitioner-role-leave-group-rooms", groupIdsForSocket);
      }
      groupIdsForSocket = [];
      dispatch(setupSessionSocket(sessions));
    }, secondsRemaining * 1000);
  };
};

const setupPatientSocket = options => {
  let retryCount = options?.retryCount || 0;
  return async dispatch => {
    let patientIds = options.patientIds;
    try {
      if (!patientSocket) {
        await dispatch(createPatientSocketConnection(patientIds));
      }
      // patientSocket.emit("practitioner-role-join-patient-rooms", patientIds);
      patientSocket.on("patient-status-update", data => {
        dispatch({ type: "ADD_PATIENT_STATUS_DATA", data });
      });
      patientSocket.on("send-mental-wellness-data", data => {
        dispatch({ type: "ADD_PATIENT_MENTAL_WELLNESS_DATA", data });
      });
    } catch (error) {
      if (retryCount < 3) {
        retryCount = retryCount + 1;
        dispatch(setupPatientSocket({ ...options, retryCount }));
      } else {
        console.log(error);
      }
    }
  };
};

const setupGroupSocket = options => {
  let retryCount = options?.retryCount || 0;
  return async dispatch => {
    let groupIds = options.groupIds;
    try {
      if (!groupSocket) {
        await dispatch(createGroupSocketConnection(groupIds));
      }
      // groupSocket.emit("practitioner-role-join-group-rooms", groupIds);
      groupSocket.on("group-status-update", data => {
        dispatch({ type: "ADD_GROUP_STATUS_DATA", data });
      });
      groupSocket.on("send-mental-wellness-data", data => {
        dispatch({ type: "ADD_GROUP_MENTAL_WELLNESS_DATA", data });
      });
    } catch (error) {
      if (retryCount < 3) {
        retryCount = retryCount + 1;
        dispatch(setupGroupSocket({ ...options, retryCount }));
      } else {
        console.log(error);
      }
    }
  };
};

const createPatientSocketConnection = patientIds => {
  return async (dispatch, getState) => {
    let state = getState();
    let selectedUserRole = state.user.selectedUserRole;
    let organizationId = selectedUserRole?.organizationId;
    let practitionerRoleId = selectedUserRole?.id;
    return new Promise((resolve, reject) => {
      patientSocket = io(IN_SESSION_SOCKET_URL + "/patient", {
        reconnection: true,
        reconnectionDelay: 1000,
        reconnectionDelayMax: 5000,
        reconnectionAttempts: 10,
        query: {
          organizationId,
          practitionerRoleId,
          practitionerRoleClient: true,
        },
      });

      patientSocket.on("connect", () => {
        patientSocket.emit("practitioner-role-join-patient-rooms", patientIds);
        console.log("patient socket connected");
        resolve(patientSocket); // Resolve the promise with the socket object
      });

      patientSocket.on("connect_error", err => {
        console.log(err.message);
        console.log(err.description);
        console.log(err.context);
        reject(err); // Reject the promise with the error
      });

      patientSocket.on("error", err => {
        console.log("Socket encountered an error", err);
        reject(err); // Handle general socket errors and reject
      });
    });
  };
};

const createGroupSocketConnection = groupIds => {
  return async (dispatch, getState) => {
    let state = getState();
    let selectedUserRole = state.user.selectedUserRole;
    let organizationId = selectedUserRole?.organizationId;
    let practitionerRoleId = selectedUserRole?.id;
    return new Promise((resolve, reject) => {
      groupSocket = io(IN_SESSION_SOCKET_URL + "/group", {
        reconnection: true,
        reconnectionDelay: 1000,
        reconnectionDelayMax: 5000,
        reconnectionAttempts: 10,
        query: {
          organizationId,
          practitionerRoleId,
          practitionerRoleClient: true,
        },
      });

      groupSocket.on("connect", () => {
        groupSocket.emit("practitioner-role-join-group-rooms", groupIds);
        console.log("group socket connected");
        resolve(groupSocket); // Resolve the promise with the socket object
      });

      groupSocket.on("connect_error", err => {
        console.log(err.message);
        console.log(err.description);
        console.log(err.context);
        reject(err); // Reject the promise with the error
      });

      groupSocket.on("error", err => {
        console.log("Socket encountered an error", err);
        reject(err); // Handle general socket errors and reject
      });
    });
  };
};

export const sendDelayMessage = async options => {
  const message = options?.message || "";
  const patientPhoneNumber = options?.patientPhoneNumber || "";
  const isGroupSession = options?.isGroupSession || "";
  const groupId = options.groupId || "";
  const patientId = options?.patientId || "";
  if (!isGroupSession) {
    if (!!patientSocket) {
      patientSocket.emit("send-session-delay-message", patientId, message);
      if (message && patientPhoneNumber) {
        let url = "practitioner-role/patient/send-text-to-phone";
        let payload = {
          contactPhone: patientPhoneNumber,
          mode: "AUTO",
          text: message,
        };
        try {
          let response = await post(url, payload);
        } catch (error) {
          console.log(error);
        }
      }
    }
  } else {
    if (!!groupSocket) {
      groupSocket.emit("send-session-delay-message", groupId, message);
    }
  }
  if (message) {
    toast.success("Message Sent");
  }
};

export const sendLiveTranscript = async options => {
  const payload = options?.payload || "";
  const patientId = options?.patientId || "";
  if (!!patientSocket) {
    patientSocket.emit("send-live-transcript", patientId, payload);
  }
};

export const sendMeetingJoinedEvent = async options => {
  const isGroupSession = options?.isGroupSession || "";
  const groupId = options?.groupId || "";
  const patientId = options?.patientId || "";
  if (!isGroupSession) {
    if (!!patientSocket) {
      patientSocket.emit("send-meeting-started-message", patientId);
    }
  } else {
    if (!!groupSocket) {
      groupSocket.emit("send-meeting-started-message", groupId);
    }
  }
};

const initState = {
  sessions: {
    data: [],
    patientStatus: {},
    groupStatus: {},
    patientMentalWellnessData: null,
    groupMentalWellnessData: null,
    loading: false,
  },
};

const ACTION_HANDLERS = {
  ["TOGGLE_SESSIONS_LOADER"]: (state, action) => {
    return {
      ...state,
      sessions: {
        ...state.sessions,
        loading: action.show,
      },
    };
  },
  ["ADD_SESSIONS_DATA"]: (state, action) => {
    return {
      ...state,
      sessions: {
        ...state.sessions,
        data: action.data,
      },
    };
  },
  ["ADD_PATIENT_STATUS_DATA"]: (state, action) => {
    return {
      ...state,
      sessions: {
        ...state.sessions,
        patientStatus: {
          ...state.sessions.patientStatus,
          [action.data.patientId]: {
            patientId: action.data.patientId,
            status: action.data.status,
            updatedAt: action.data.updatedAt,
          },
        },
      },
    };
  },
  ["ADD_GROUP_STATUS_DATA"]: (state, action) => {
    return {
      ...state,
      sessions: {
        ...state.sessions,
        groupStatus: {
          ...state.sessions.groupStatus,
          [action.data.groupId]: {
            groupId: action.data.groupId,
            patients: action.data.patients,
            updatedAt: action.data.updatedAt,
          },
        },
      },
    };
  },
  ["ADD_PATIENT_MENTAL_WELLNESS_DATA"]: (state, action) => {
    return {
      ...state,
      sessions: {
        ...state.sessions,
        patientMentalWellnessData: {
          ...state.sessions.patientMentalWellnessData,
          [action.data?.patientId]: action.data,
        },
      },
    };
  },
  ["ADD_GROUP_MENTAL_WELLNESS_DATA"]: (state, action) => {
    return {
      ...state,
      sessions: {
        ...state.sessions,
        groupMentalWellnessData: {
          ...state.sessions.groupMentalWellnessData,
          [action.data?.groupId]: action.data,
        },
      },
    };
  },
};

export default function appointments(state = initState, action) {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
}
