import React, { useEffect } from "react";
import SessionCard from "@/Routes/Appointments/components/Sessions/components/SessionCard";
import { Loader } from "@/components";
import "./UpcomingSessions.scss";

function UpcomingSessions(props) {
  let loading = props.sessions?.loading;
  let upcomingSessions = props.sessions.data.filter(session => {
    const today = new Date(
      new Date().toLocaleString("en", {
        timeZone: props.preferredTimezone?.name || "America/Los_Angeles",
      })
    );
    today.setHours(0, 0, 0, 0);
    const dateToCheck = new Date(
      new Date(session.startTime).toLocaleString("en", {
        timeZone: props.preferredTimezone?.name || "America/Los_Angeles",
      })
    );
    dateToCheck.setHours(0, 0, 0, 0);
    return (
      dateToCheck.getTime() === today.getTime()
      // &&
      // !!session.endTime &&
      // new Date(session.endTime) >= new Date()
    );
  });
  upcomingSessions = upcomingSessions.sort(
    (session1, session2) => new Date(session1.startTime) - new Date(session2.startTime)
  );
  useEffect(() => {
    if (!props.sessions.loading && props.sessions.data.length === 0) {
      props.getSessionsByPractitionerRole();
    }
  }, []);
  return (
    <div className="dashboard-widget upcoming-today-widget-container">
      <div className="widget-header">
        <div className="widget-title">Today's Appointment</div>
      </div>
      <Loader loading={loading}>
        {upcomingSessions?.length > 0 ? (
          <div className="sessions-list">
            {upcomingSessions.map(session => {
              return (
                <SessionCard
                  session={session}
                  isUpcoming={true}
                  key={session.id}
                  getSessionsByPractitionerRole={props.getSessionsByPractitionerRole}
                />
              );
            })}
          </div>
        ) : (
          <div className="no-sessions-found-container">
            <div className="no-sessions-found">No Sessions Found</div>
          </div>
        )}
      </Loader>
    </div>
  );
}

export default UpcomingSessions;
