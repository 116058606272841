import React, { useEffect, useState, useRef } from "react";
import { Loader, MemberAvatar, AlertModal } from "@/components";
import { Link, useNavigate, useParams } from "react-router-dom";
import moment from "moment-timezone";
import WaitingStatus from "@/Routes/Appointments/components/Sessions/components/WaitingStatus";
import InPersonIcon from "@/assets/sofa.svg";
import VirtualIcon from "@/assets/video-recorder.svg";
import PhoneSpeakerIcon from "@/assets/phone-speaker.svg";
import GroupsPlaceholderIcon from "@/assets/group-avatar-placeholder.svg";
import PhoneIcon from "@/assets/phone.svg";
import ShareIcon from "@/assets/share.svg";
import IntakeIcon from "@/assets/intake.svg";
import "./SessionAppointments.scss";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { ReactSVG } from "react-svg";
import { SendText } from "@/components";

const APP_ENV = import.meta.env.VITE_APP_ENV || "PROD";
const isMobile = window.innerWidth <= 480;

function SessionAppointments(props) {
  const { sessionCategory, sessionType, sessionId, patientId } = useParams();
  let loading = props.sessions?.loading;
  let upcomingSessions = props.sessions.data.filter(session => {
    const today = new Date(
      new Date().toLocaleString("en", {
        timeZone: props.preferredTimezone?.name || "America/Los_Angeles",
      })
    );
    today.setHours(0, 0, 0, 0);
    const dateToCheck = new Date(
      new Date(session.startTime).toLocaleString("en", {
        timeZone: props.preferredTimezone?.name || "America/Los_Angeles",
      })
    );
    dateToCheck.setHours(0, 0, 0, 0);
    return (
      dateToCheck.getTime() === today.getTime()
      // &&
      // !!session.endTime &&
      // new Date(session.endTime) >= new Date()
    );
  });
  upcomingSessions = upcomingSessions.sort(
    (session1, session2) => new Date(session1.startTime) - new Date(session2.startTime)
  );
  useEffect(() => {
    if (!props.sessions.loading && props.sessions.data.length === 0) {
      props.getSessionsByPractitionerRole();
    }
  }, []);
  const liveSessionCard = useRef(null);
  useEffect(() => {
    if (upcomingSessions.length > 0 && liveSessionCard.current && !isMobile) {
      liveSessionCard.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  }, [upcomingSessions]);
  return (
    <div className="session-appointments-container">
      <Loader loading={loading}>
        <div className="session-appointments-header">
          <div className="session-appointments-label">Your Next Appointments</div>
        </div>
        {upcomingSessions?.length > 0 ? (
          <div className="session-todays-appointment-list">
            {upcomingSessions.map((session, index) => {
              return (
                <SessionAppointmentCard
                  key={session.id}
                  session={session}
                  preferredTimezone={props.preferredTimezone}
                  sessionEnded={props.sessionEnded}
                  endSession={props.endSession}
                  liveSessionId={sessionId}
                  livePatientId={patientId}
                  currentSessionStatus={props.currentSessionStatus}
                  liveSessionCard={liveSessionCard}
                />
              );
            })}
          </div>
        ) : (
          <div className="no-appointments-container">No Appointments Found</div>
        )}
      </Loader>
    </div>
  );
}

export default SessionAppointments;

const SessionAppointmentCard = props => {
  const [showSMSMeetingLinkPopup, setShowSMSMeetingLinkPopup] = useState(false);
  const session = props.session;
  const sessionTime = `${moment(session.startTime)
    .tz(props.preferredTimezone?.name || "America/Los_Angeles")
    .format("h:mm a")} ${props.preferredTimezone?.abbr || "PT"} | ${
    session.durationInMinutes
  } mins`;
  const smsSessionTime = `${moment(session.startTime)
    .tz(props.preferredTimezone?.name || "America/Los_Angeles")
    .format("h:mm a")} ${props.preferredTimezone?.abbr || "PT"}`;
  const isExternalSpeakerPhone = session.sessionName?.includes("Phone session");
  let todayDate = new Date(
    new Date().toLocaleString("en", {
      timeZone: props.preferredTimezone?.name || "America/Los_Angeles",
    })
  );
  let sessionEndDate = new Date(
    new Date(session.endTime).toLocaleString("en", {
      timeZone: props.preferredTimezone?.name || "America/Los_Angeles",
    })
  );
  let isSessionEnded = todayDate > sessionEndDate;
  let isLiveSession =
    (session?.id === props.liveSessionId &&
      session?.sessionCategory === "GROUP" &&
      session?.groupId === props.livePatientId) ||
    (session?.id === props.liveSessionId &&
      session?.sessionCategory === "INDIVIDUAL" &&
      session?.patientId === props.livePatientId);
  let orgName = session?.organizationName || "";
  let sessionDate = session?.startTime
    ? moment(session.startTime)
        .tz(props.preferredTimezone?.name || "America/Los_Angeles")
        .format("Do MMMM")
    : "";
  const meetingLink = `https://app${
    APP_ENV === "DEV" ? ".dev" : ""
  }.soulsidehealth.com/virtual-session/${session?.patientId}`;
  const patientPhoneNumber = session?.patientPhoneNumber || "";
  const providerName = `${session?.practitionerFirstName || ""}${
    session?.practitionerLastName ? " " : ""
  }${session?.practitionerLastName || ""}`;
  let patientName = session?.patientFirstName || "";
  let meetingLinkMessage = `Hi ${patientName},\n\nYour appointment ${
    orgName ? `at ${orgName} ` : ""
  }with Dr. ${providerName} is scheduled for ${sessionDate} at ${smsSessionTime}.\n\nYou can join your appointment with the following link:\n${meetingLink}`;
  return (
    <div
      className="session-appointment-card"
      ref={isLiveSession ? props.liveSessionCard : null}
    >
      <div className="appointment-card-top">
        <div className="appointment-card-time-container">
          <div className="appointment-card-time">{sessionTime}</div>
          {!isLiveSession &&
            session.modeOfDelivery === "VIRTUAL" &&
            session.sessionCategory === "INDIVIDUAL" && (
              <OverlayTrigger
                overlay={<Tooltip>Share Meeting Link</Tooltip>}
                rootClose={true}
              >
                <ReactSVG
                  src={ShareIcon}
                  className="appointment-share-link-icon"
                  onClick={() => setShowSMSMeetingLinkPopup(true)}
                />
              </OverlayTrigger>
            )}
          <SendText
            modalOpen={showSMSMeetingLinkPopup}
            toggleModal={setShowSMSMeetingLinkPopup}
            phoneNumber={patientPhoneNumber}
            message={meetingLinkMessage}
          />
        </div>
        {!!isLiveSession ? (
          <div className="session-live-tag">{props.currentSessionStatus}</div>
        ) : (
          <SessionStartLink
            session={session}
            isExternalSpeakerPhone={isExternalSpeakerPhone}
            sessionEnded={props.sessionEnded}
            endSession={props.endSession}
            liveSessionId={props.liveSessionId}
            livePatientId={props.livePatientId}
          />
        )}
      </div>
      {patientPhoneNumber && (
        <div className="patient-contact-item">
          <ReactSVG
            src={PhoneIcon}
            className="patient-contact-icon"
          />
          <span className="patient-contact-value">{patientPhoneNumber}</span>
        </div>
      )}
      <div className="appoinment-card-bottom">
        <div className="appointment-status-container">
          <div className="session-type">
            <img
              className="session-type-icon"
              src={
                session.modeOfDelivery === "VIRTUAL"
                  ? VirtualIcon
                  : isExternalSpeakerPhone
                  ? PhoneSpeakerIcon
                  : InPersonIcon
              }
            />
            {session.modeOfDelivery === "VIRTUAL"
              ? "Online"
              : isExternalSpeakerPhone
              ? "Phone"
              : "In-Office"}
          </div>
          {session.sessionCategory === "INDIVIDUAL" && session.appointmentType === "INTAKE" && (
            <div className="session-type">
              <img
                className="session-type-icon"
                src={IntakeIcon}
              />
              Intake
            </div>
          )}
          <WaitingStatus session={session} />
        </div>
        {session.sessionCategory === "GROUP" ? (
          <MemberAvatar
            name={
              session.sessionCategory === "GROUP"
                ? session.groupName
                : `${session.patientFirstName} ${session.patientLastName}`
            }
            size={"sm"}
            showFullName={true}
            placeholderImg={session.sessionCategory === "GROUP" ? GroupsPlaceholderIcon : null}
          />
        ) : (
          <div className="patient-name">
            {session.sessionCategory === "GROUP"
              ? session.groupName
              : `${session.patientFirstName} ${session.patientLastName}`}
          </div>
        )}
      </div>
    </div>
  );
};

const SessionStartLink = props => {
  const navigate = useNavigate();
  let session = props.session;
  const [secondsRemaining, setSecondsRemaing] = useState(0);
  const [showWarning, setShowWarning] = useState(false);
  useEffect(() => {
    let scheduledFor = session.startTime;
    let remainingSeconds = (new Date(scheduledFor) - new Date()) / 1000;
    setSecondsRemaing(remainingSeconds);
    let intervalId = setInterval(() => {
      let scheduledFor = session.startTime;
      let remainingSeconds = (new Date(scheduledFor) - new Date()) / 1000;
      setSecondsRemaing(remainingSeconds);
    }, 1000);
    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, []);
  const minutesRemaining = secondsRemaining / 60;
  const sessionUpcoming = minutesRemaining <= 60 && minutesRemaining > 0;
  // const sessionStarted = minutesRemaining <= 0 && Math.abs(minutesRemaining) <= 75;
  const sessionStarted = minutesRemaining <= 0;
  let showSessionStartLink = sessionUpcoming || sessionStarted;
  if (!showSessionStartLink) {
    return <></>;
  }
  const meetingLink = `/session/${session.sessionCategory === "GROUP" ? "group" : "individual"}/${
    session.modeOfDelivery === "VIRTUAL" ? "virtual" : "in-person"
  }/${session.id}/${session.sessionCategory === "GROUP" ? session.groupId : session.patientId}${
    props.isExternalSpeakerPhone ? "?externalSpeakerPhone=true" : ""
  }`;
  const startSession = async () => {
    if (!props.sessionEnded) {
      setShowWarning(true);
    } else {
      await props.endSession();
      window.location.href = window.location.origin + meetingLink;
      // navigate(meetingLink, { replace: true });
    }
  };
  return (
    <>
      <div
        className="start-session-button"
        onClick={startSession}
      >
        Start Session
      </div>
      <AlertModal
        modalOpen={showWarning}
        onCancel={() => {
          setShowWarning(false);
        }}
        onConfirm={async () => {
          await props.endSession();
          setShowWarning(false);
          window.location.href = window.location.origin + meetingLink;
          // navigate(meetingLink, { replace: true });
        }}
        title={"End current session?"}
        desc={"Current appointment will get ended and new appointment will be started"}
        confirmBtnText={"Proceed"}
        cancelBtnText={"Cancel"}
      />
    </>
  );
};
