import React, { useEffect, useState } from "react";
import { Loader } from "@/components";
import { Link, useParams } from "react-router-dom";
import Profile from "./components/Profile";
import PreExistingRisks from "./components/PreExistingRisks";
import Recommendation from "./components/Recommendation";
import RecommendationOld from "./components/RecommendationOld";
import CumulativeNotes from "./components/CumulativeNotes";
import DSM5 from "./components/DSM5";
import SDOH from "./components/SDOH";
import SymptomTracker from "./components/SymptomTracker";
import PredictionCards from "./components/PredictionCards";
import PatientSessions from "./components/PatientSessions";
import "./PatientDetails.scss";

const APP_ENV = import.meta.env.VITE_APP_ENV || "PROD";

const getIntensityColor = (intensity, key) => {
  // let color = "#74dd74";
  let color = "low";
  let intensitySmallCase = intensity?.toLowerCase() || "";
  if (intensitySmallCase.includes("severe") || intensitySmallCase.includes("present")) {
    // color = "#FF5F05";
    color = "severe";
  } else if (
    intensitySmallCase.includes("moderate") ||
    (key === "emotional_distress" && intensitySmallCase.includes("high"))
  ) {
    // color = "#FF5F05";
    color = "high";
  } else if (
    intensitySmallCase.includes("mild") ||
    (key === "emotional_distress" && intensitySmallCase.includes("medium"))
  ) {
    // color = "#64C833";
    color = "medium";
  } else if (
    intensitySmallCase.includes("low") ||
    (key === "emotional_distress" && intensitySmallCase.includes("low"))
  ) {
    // color = "44C13C";
    color = "low";
  }
  return color;
};

function PatientDetails(props) {
  const {
    soapNotes,
    soapNotesLoading,
    behaviouralHealthPredictions,
    privateNotesLoading,
    privateNotes,
  } = props;
  const { patientId } = useParams();
  const [riskAssessmemtData, setRiskAssessmentData] = useState([]);
  const [treatmentPlanData, setTreatmentPlanData] = useState([]);
  const [diagnosisData, setDiagnosisData] = useState(null);
  const [behaviouralHealthPredictionsData, setBehaviouralHealthPredictionsData] = useState(null);
  const patientsList = props.patients.data;
  useEffect(() => {
    if (patientsList?.length === 0 && !props.patients.loading) {
      props.getPatients();
    }
  }, []);

  const patientData = patientsList.find(i => i.id === patientId);
  useEffect(() => {
    if (patientData?.firstName) {
      let patientName = `${patientData.firstName || ""} ${patientData.lastName || ""}`;
      document.title = patientName;
    } else {
      document.title = "Patient Details";
    }
  }, [patientData]);
  useEffect(() => {
    if (!soapNotesLoading) {
      props.getLatestSoapNotes({ patientId });
    }
    // props.getPatientPrivateNotes({ groupId, patientId });
    return () => {
      props.resetPatientDetails();
    };
  }, []);
  useEffect(() => {
    // if (soapNotes) {
    //   let riskAssessmemtData = parseHtml(soapNotes, "Assessment (A)");
    //   setRiskAssessmentData(riskAssessmemtData);
    //   let treatmentPlanData = parseHtml(soapNotes, "Plan (P)");
    //   setTreatmentPlanData(treatmentPlanData);
    // }
  }, [soapNotes]);
  useEffect(() => {
    if (behaviouralHealthPredictions) {
      let data = behaviouralHealthPredictions || null;
      if (data && Object.keys(data).length > 0) {
        let anxietyParameters = data?.["gad-7"] || {};
        let depressionParameters = data?.["phq-9"] || {};
        anxietyParameters = Object.keys(anxietyParameters).map(parameter => {
          return {
            title: parameter,
            detected: anxietyParameters[parameter] > 0,
            intensity: anxietyParameters[parameter],
          };
        });
        depressionParameters = Object.keys(depressionParameters).map(parameter => {
          return {
            title: parameter,
            detected: depressionParameters[parameter] > 0,
            intensity: depressionParameters[parameter],
          };
        });
        let anxietyScore = anxietyParameters.reduce((result, i) => {
          return i.intensity + result;
        }, 0);
        let depressionScore = depressionParameters.reduce((result, i) => {
          return i.intensity + result;
        }, 0);
        let anxietyIntensityText =
          anxietyScore >= 15
            ? "Severe"
            : anxietyScore >= 10
            ? "Moderate"
            : anxietyScore >= 5
            ? "Mild"
            : "Low";
        let depressionIntensityText =
          depressionScore >= 20
            ? "Severe"
            : depressionScore >= 15
            ? "Moderately severe"
            : depressionScore >= 10
            ? "Moderate"
            : depressionScore >= 5
            ? "Mild"
            : "Low";
        let anxietyData = {
          parameters: anxietyParameters,
          score: anxietyScore,
          intensityText: anxietyIntensityText,
          cardColor: getIntensityColor(anxietyIntensityText),
        };
        let depressionData = {
          parameters: depressionParameters,
          score: depressionScore,
          intensityText: depressionIntensityText,
          cardColor: getIntensityColor(depressionIntensityText),
        };
        let emotionalDistress = {
          intensityText: data?.emotional_distress || "-",
          cardColor: getIntensityColor(data?.emotional_distress, "emotional_distress"),
        };
        let suicideRisk = {
          intensityText: data?.suicide_risk || "",
          cardColor: getIntensityColor(data?.suicide_risk),
        };
        let predictData = {
          anxiety: anxietyData,
          depression: depressionData,
          emotionalDistress,
          suicideRisk,
        };
        setBehaviouralHealthPredictionsData(predictData);
      }
    }
  }, [behaviouralHealthPredictions]);
  useEffect(() => {
    // if (soapNotes) {
    //   let diagnosisList = parseDiagnosisHtml(soapNotes, "Diagnostic Impressions");
    //   setDiagnosisData(diagnosisList);
    //   // if (diagnosisList.length === 0) {
    //   //   diagnosisList = parseDiagnosisHtml(soapNotes, "Possible Diagnoses");
    //   // }
    //   // if (diagnosisList?.length > 0) {
    //   //   let diagnosisSyndromes = [];
    //   //   let diagnosisDescription = "";
    //   //   let dsm5Predictions = [];
    //   //   diagnosisList.forEach(i => {
    //   //     let text = i;
    //   //     if (text.includes("Possible diagnoses:")) {
    //   //       let newText = text.replace("Possible diagnoses: ", "");
    //   //       let syndromes = newText.split(", ");
    //   //       syndromes = syndromes.filter(
    //   //         i => !i.includes("None at this time") && !i.includes("Not applicable")
    //   //       );
    //   //       diagnosisSyndromes = [...diagnosisSyndromes, ...syndromes];
    //   //     }
    //   //     if (text.includes("Possible diagnosis:")) {
    //   //       let newText = text.replace("Possible diagnosis: ", "");
    //   //       let syndromes = newText.split(", ");
    //   //       syndromes = syndromes.filter(
    //   //         i => !i.includes("None at this time") && !i.includes("Not applicable")
    //   //       );
    //   //       diagnosisSyndromes = [...diagnosisSyndromes, ...syndromes];
    //   //     }
    //   //     if (text.includes("Comorbidity:")) {
    //   //       let newText = text.replace("Comorbidity: ", "");
    //   //       let syndromes = newText.split(", ");
    //   //       syndromes = syndromes.filter(
    //   //         i => !i.includes("None at this time") && !i.includes("Not applicable")
    //   //       );
    //   //       diagnosisSyndromes = [...diagnosisSyndromes, ...syndromes];
    //   //     }
    //   //     if (text.includes("Rationale:")) {
    //   //       diagnosisDescription = text.replace("Rationale: ", "");
    //   //     }
    //   //     if (text.includes("DSM-5 Prediction:")) {
    //   //       let newText = text.replace("DSM-5 Prediction: ", "");
    //   //       dsm5Predictions = newText.split(", ");
    //   //     }
    //   //   });
    //   //   let data = {
    //   //     syndromes: diagnosisSyndromes,
    //   //     description: diagnosisDescription,
    //   //     dsm5Predictions,
    //   //   };
    //   //   setDiagnosisData(data);
    //   // }
    // } else {
    //   if (!soapNotesLoading) {
    //     setDiagnosisData(null);
    //   }
    // }
  }, [soapNotes]);
  return (
    <div className="member-report-page">
      <Link
        className="back-btn"
        to={"/patients"}
      >
        Back
      </Link>
      <Loader loading={soapNotesLoading || privateNotesLoading}>
        <div className="patient-details-wrapper">
          <div className="patient-details-container">
            {/* <div className="patient-details-top-container">
            </div> */}
            <div className="masonry-layout">
              <div className="masonry-left">
                <div className="member-report-widget">
                  <Profile
                    data={patientData}
                    behaviouralHealthPredictionsData={behaviouralHealthPredictionsData}
                    getDummyPhoneNumber={props.getDummyPhoneNumber}
                    getDummyEmail={props.getDummyEmail}
                    dischargeSummary={props.dischargeSummary[patientId]}
                    dischargeSummarySession={props.dischargeSummarySession[patientId]}
                    generateDischargeSummary={props.generateDischargeSummary}
                    sessions={props.sessions.data}
                    selectedUserRole={props.selectedUserRole}
                    getDischargeSummary={props.getDischargeSummary}
                    saveDischargeSummary={props.saveDischargeSummary}
                    preferredTimezone={props.preferredTimezone}
                  />
                </div>
                {riskAssessmemtData && false && (
                  <div className="member-report-widget">
                    <PreExistingRisks data={riskAssessmemtData} />
                  </div>
                )}
                {props.soapNotesJson?.narrative && (
                  <div className="member-report-widget patient-narrative-widget">
                    <div className="widget-header">
                      <div className="widget-title">Latest Patient Narrative</div>
                    </div>
                    <textarea
                      className="patient-narrative-widget-content"
                      readOnly={true}
                    >
                      {props.soapNotesJson.narrative}
                    </textarea>
                  </div>
                )}
                {props.soapNotesJson?.plan?.["follow_up_plans"]?.result?.length > 0 && (
                  <div className="member-report-widget patient-narrative-widget">
                    <div className="widget-header">
                      <div className="widget-title">Follow-up Plans</div>
                    </div>
                    <div className="follow-up-plan-list">
                      {props.soapNotesJson?.plan?.["follow_up_plans"]?.result.map(
                        (result, index) => {
                          return (
                            <div
                              className="follow-up-plan-item"
                              key={index}
                            >
                              {result}
                            </div>
                          );
                        }
                      )}
                    </div>
                  </div>
                )}
                {/* <div className="member-report-widget">
                  <CumulativeNotes data={privateNotes} />
                </div> */}
                {APP_ENV === "DEV" && (
                  <div className="member-report-widget">
                    <SDOH data={{ sdohParameters: ["living", "food"] }} />
                  </div>
                )}
              </div>
              <div className="masonry-right">
                {treatmentPlanData && (
                  <div className="member-report-widget">
                    <Recommendation
                      data={treatmentPlanData}
                      sessions={props.sessions.data}
                      preferredTimezone={props.preferredTimezone}
                    />
                  </div>
                )}
                {props.soapNotesJson?.assessment?.["DSM-5 Diagnoses"]?.result && (
                  <div className="member-report-widget patient-narrative-widget">
                    <div className="widget-header">
                      <div className="widget-title">DSM-5 Diagnosis</div>
                    </div>
                    <textarea
                      className="patient-narrative-widget-content"
                      readOnly={true}
                      style={{ minHeight: "unset" }}
                    >
                      {props.soapNotesJson?.assessment?.["DSM-5 Diagnoses"]?.result}
                    </textarea>
                  </div>
                )}
                {props.soapNotesJson?.assessment?.["Current Diagnosis"]?.result?.length > 0 && (
                  <div className="member-report-widget patient-narrative-widget">
                    <div className="widget-header">
                      <div className="widget-title">Current Diagnosis</div>
                    </div>
                    <textarea
                      className="patient-narrative-widget-content"
                      readOnly={true}
                      style={{ minHeight: "unset" }}
                      value={
                        props.soapNotesJson?.assessment?.["Current Diagnosis"]?.result
                          ? typeof (
                              props.soapNotesJson?.assessment?.["Current Diagnosis"]?.result ===
                              "string"
                            )
                            ? props.soapNotesJson?.assessment?.["Current Diagnosis"]?.result
                            : props.soapNotesJson?.assessment?.[
                                "Current Diagnosis"
                              ]?.result?.join?.(", ")
                          : ""
                      }
                    />
                  </div>
                )}

                {APP_ENV === "PROD" && treatmentPlanData && false && (
                  <div className="member-report-widget">
                    <RecommendationOld data={treatmentPlanData} />
                  </div>
                )}
                {!!diagnosisData && false && (
                  <div className="member-report-widget">
                    <DSM5 data={diagnosisData} />
                  </div>
                )}
                {!!behaviouralHealthPredictionsData && (
                  <div className="member-report-widget">
                    <SymptomTracker data={behaviouralHealthPredictionsData} />
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="member-report-widget sessions-report-widget">
            <PatientSessions
              patientId={patientId}
              sessions={props.sessions}
              getSessionsByPractitionerRole={props.getSessionsByPractitionerRole}
              preferredTimezone={props.preferredTimezone}
            />
          </div>
        </div>
      </Loader>
    </div>
  );
}

export default PatientDetails;

function parseHtml(htmlString, title) {
  // Parse the HTML string into a DOM document
  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlString, "text/html");

  // Function to find the h3 element containing "Risk Assessment"
  const headings = Array.from(doc.querySelectorAll("h2"));
  const riskAssessmentHeading = headings.find(
    heading => heading.textContent.trim() === `${title}:` || heading.textContent.trim() === title
  );

  let content = null;

  // If the Risk Assessment section is found
  if (riskAssessmentHeading) {
    // Get the next sibling element, which should be the <ul> list
    content = "";
    let nextSibling = riskAssessmentHeading.nextElementSibling;
    while (nextSibling && nextSibling.tagName !== "h2") {
      content += nextSibling.outerHTML;
      nextSibling = nextSibling.nextElementSibling;
    }
    return content;
    // const riskAssessmentList = riskAssessmentHeading.nextElementSibling;
    // return riskAssessmentList ? riskAssessmentList.outerHTML : "";
    // Check if the next sibling is a <ul> element
    // if (riskAssessmentList && riskAssessmentList.tagName === "UL") {
    //   // Extract the list items from the Risk Assessment section
    //   const riskAssessmentItems = [];
    //   const listItems = riskAssessmentList.querySelectorAll("li");
    //   listItems.forEach(item => {
    //     riskAssessmentItems.push(item.textContent.trim());
    //   });

    //   return riskAssessmentItems;
    // }
  }

  // Return an empty array if the Risk Assessment section is not found or does not contain a <ul> list
  return null;
}

function parseDiagnosisHtml(htmlString, title) {
  // Parse the HTML string into a DOM document
  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlString, "text/html");

  // Function to find the h3 element containing "Risk Assessment"
  const headings = Array.from(doc.querySelectorAll("h4"));
  const riskAssessmentHeading = headings.find(
    heading => heading.textContent.trim() === `${title}:` || heading.textContent.trim() === title
  );

  let content = null;

  // If the Risk Assessment section is found
  if (riskAssessmentHeading) {
    // Get the next sibling element, which should be the <ul> list
    content = "";
    let nextSibling = riskAssessmentHeading.nextElementSibling;
    // while (nextSibling && nextSibling.tagName !== "h2") {
    content += nextSibling.outerHTML;
    // nextSibling = nextSibling.nextElementSibling;
    // }
    return content;
    // const riskAssessmentList = riskAssessmentHeading.nextElementSibling;
    // return riskAssessmentList ? riskAssessmentList.outerHTML : "";
    // Check if the next sibling is a <ul> element
    // if (riskAssessmentList && riskAssessmentList.tagName === "UL") {
    //   // Extract the list items from the Risk Assessment section
    //   const riskAssessmentItems = [];
    //   const listItems = riskAssessmentList.querySelectorAll("li");
    //   listItems.forEach(item => {
    //     riskAssessmentItems.push(item.textContent.trim());
    //   });

    //   return riskAssessmentItems;
    // }
  }

  // Return an empty array if the Risk Assessment section is not found or does not contain a <ul> list
  return null;
}

function parseHtmlJson(htmlString, title) {
  // Parse the HTML string into a DOM document
  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlString, "text/html");

  // Function to find the h3 element containing "Risk Assessment"
  const headings = Array.from(doc.querySelectorAll("h3"));
  const riskAssessmentHeading = headings.find(
    heading => heading.textContent.trim() === `${title}:` || heading.textContent.trim() === title
  );

  // If the Risk Assessment section is found
  if (riskAssessmentHeading) {
    // Get the next sibling element, which should be the <ul> list
    const riskAssessmentList = riskAssessmentHeading.nextElementSibling;
    // Check if the next sibling is a <ul> element
    if (riskAssessmentList && riskAssessmentList.tagName === "UL") {
      // Extract the list items from the Risk Assessment section
      const riskAssessmentItems = [];
      const listItems = riskAssessmentList.querySelectorAll("li");
      listItems.forEach(item => {
        riskAssessmentItems.push(item.textContent.trim());
      });

      return riskAssessmentItems;
    }
  }

  // Return an empty array if the Risk Assessment section is not found or does not contain a <ul> list
  return [];
}
